<template>
    <v-btn
        :size="size"
        :density="density"
        :color="color"
        :variant="variant"
        :to="vesselTo"
        class="vesselButton"
        title="View Vessel Details"
        :target="target"
        :prepend-icon="iconForVesselType"
        :append-icon="showOpenInIcon && target !== undefined ? 'mdi-open-in-new' : undefined"
    >
        <span class="name">{{ vessel.name }}</span></v-btn
    >
</template>

<script lang="ts">
import { VesselTypes, type MonitorDetailsAttachmentHistoryVesselV1, type MonitorDetailsVesselV1, type VesselSummaryV1 } from '@geckoal/gecko-api-client'

export type VesselSummary = {
    type: VesselTypes
    name: string
    vesselId: number
    accountId: number
}
</script>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'

const props = defineProps({
    vessel: {
        type: Object as PropType<VesselSummary | VesselSummaryV1 | MonitorDetailsVesselV1 | MonitorDetailsAttachmentHistoryVesselV1>,
        required: true
    },
    size: {
        type: String as PropType<'x-small' | 'small' | 'large' | 'x-large'>,
        default: 'large'
    },
    density: {
        type: String as PropType<'compact' | 'comfortable'>
    },
    variant: {
        type: String as PropType<'elevated' | 'flat' | 'tonal' | 'outlined' | 'text' | 'plain'>,
        default: 'tonal'
    },
    color: {
        type: String,
        default: 'primary'
    },
    target: {
        type: String
    },
    showOpenInIcon: {
        type: Boolean,
        default: false
    }
})

const vesselTo = computed(() => {
    return {
        name: 'VesselDetails',
        params: { accountId: props.vessel.accountId, vesselId: props.vessel.vesselId }
    }
})

const iconForVesselType = computed(() => {
    if (props.vessel.type === VesselTypes.Hottub) return 'mdi-hot-tub'
    if (props.vessel.type === VesselTypes.Swimspa) return 'mdi-hot-tub'
    if (props.vessel.type === VesselTypes.Pool) return 'mdi-pool'
    return undefined
})
</script>

<style scoped lang="scss">
.vesselButton {
    text-transform: none;
}
</style>
