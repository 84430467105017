<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col sm="12">
                    <h3 class="mb-4">Vessel Status Update Lambda Logs</h3>
                    <v-progress-circular indeterminate v-if="loading" />
                    <div v-if="!loading && messages">
                        <div v-for="message of messages" :key="message.ptr" class="message">
                            <pre>{{ format(message.date, 'Pp') }} {{ message.message }}</pre>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script setup lang="ts">
import type { GetVesselStatusLambdaLogsMessageV1 } from '@geckoal/gecko-api-client'
import { ref, onMounted } from 'vue'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useApi } from '@/api'
import { useVesselStore } from '@/stores/vessel'
import { add, sub, format } from 'date-fns'
import { handleApiError } from '@/lib/utils'

const breadCrumbsStore = useBreadCrumbsStore()
const vesselStore = useVesselStore()
const loading = ref(false)
const api = useApi()
const messages = ref<GetVesselStatusLambdaLogsMessageV1[] | undefined>()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    awsRequestId: {
        type: String,
        required: true
    },
    eventDate: {
        type: String,
        required: true
    }
})

onMounted(async () => {
    await vesselStore.loadVessel(props.accountId, props.vesselId)
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`,
                to: {
                    name: 'AccountDetails',
                    params: {
                        accountId: props.accountId
                    }
                }
            },
            {
                text: vesselStore.vessel?.name,
                to: {
                    name: 'VesselDetails',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: 'Chem-Check History',
                to: {
                    name: 'VesselChemCheckHistory',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: props.awsRequestId
            },
            {
                text: 'Logs'
            }
        ]
    })
    getLogs()
})

async function getLogs() {
    const eventDate = new Date(props.eventDate)
    const startDate = sub(eventDate, { minutes: 1 })
    const endDate = add(eventDate, { minutes: 1 })

    handleApiError(
        async () => {
            loading.value = true
            const results = await api.getVesselStatusUpdateLambdaLogsV1({
                getVesselStatusLambdaLogsRequestV1: {
                    requestId: props.awsRequestId,
                    startDate,
                    endDate
                }
            })

            messages.value = results.messages
        },
        async () => {
            loading.value = false
        }
    )
}
</script>

<style lang="scss" scoped>
.message {
    &:hover {
        background-color: silver;
    }

    pre {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    margin-bottom: 8px;
}
</style>
