<template>
    <v-row>
        <v-col xs="12" sm="12" md="6" lg="3">
            <h2 class="mb-10">{{ serialNumber }}</h2>
        </v-col>
        <v-col class="text-right">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" title="Actions" density="compact" size="x-large" v-bind="props"></v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item button title="Report a Failure" @click="showReportAFailureModal = true">
                            <template v-slot:prepend>
                                <v-icon color="red-darken-2" icon="mdi-message-alert" class="mr-2"></v-icon>
                            </template>
                        </v-list-item>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>
    <div v-if="loadingSensorDetails" class="text-center mt-10">
        <v-progress-circular indeterminate color="blue" size="50"></v-progress-circular>
    </div>
    <v-row v-if="sensorDetails">
        <v-col md="6">
            <v-card>
                <v-table>
                    <tbody>
                        <tr>
                            <th class="text-left pl-4">Sensor Type</th>
                            <td>{{ sensorDetails.sensorType }}</td>
                        </tr>
                        <tr v-if="sensorDetails.manufacturedDate">
                            <th class="text-left pl-4">Manufactured Date</th>
                            <td>{{ format(sensorDetails.manufacturedDate, 'M/d/yy z', { timeZone: timezone.zone }) }}</td>
                        </tr>
                        <tr v-if="sensorDetails.monitor?.monitorId">
                            <th class="text-left pl-4">Monitor</th>
                            <td>
                                <monitor-button v-if="sensorDetails.monitor?.monitorId !== undefined" :monitor-id="sensorDetails.monitor.monitorId" />
                            </td>
                        </tr>
                        <tr v-if="sensorDetails.monitor?.vessel?.vesselId">
                            <th class="text-left pl-4">Vessel</th>
                            <td>
                                <vessel-button :vessel="sensorDetails.monitor?.vessel" />
                            </td>
                        </tr>
                        <tr v-if="sensorDetails.calibrations">
                            <th class="text-left pl-4">pH Offset</th>
                            <td>{{ sensorDetails.calibrations.phOffsetMv }} mV</td>
                        </tr>
                        <tr v-if="sensorDetails.calibrations">
                            <th class="text-left pl-4">pH Slope</th>
                            <td>{{ sensorDetails.calibrations.mvPerPhSlope }} mV/pH</td>
                        </tr>
                        <tr v-if="sensorDetails.calibrations">
                            <th class="text-left pl-4">ORP Offset</th>
                            <td>{{ sensorDetails.calibrations.orpOffsetMv }} mV</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
        </v-col>
    </v-row>

    <v-row v-if="sensorFailures && sensorFailures.length > 0" class="mt-8 mb-8">
        <v-col>
            <v-card>
                <v-card-title>Reported Failures</v-card-title>
                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left pl-4">Failure Type</th>

                            <th class="text-left pl-4">Entered Water Date</th>
                            <th class="text-left pl-4">Failure Start Date</th>
                            <th class="text-left pl-4">Time In Use</th>

                            <th class="text-left pl-4">Status</th>
                            <th class="text-left pl-4">Location</th>

                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(failure, index) in sensorFailures" :key="index">
                            <td>
                                <sensor-failure-type-chip :failure-type="failure.failureType" />
                            </td>

                            <td>{{ format(failure.enterWaterDate, 'M/d/yy h:mmaaaaa z', { timeZone: timezone.zone }) }}</td>
                            <td>{{ format(failure.failureStartDate, 'M/d/yy h:mmaaaaa z', { timeZone: timezone.zone }) }}</td>
                            <td>{{ failure.timeInUse }}</td>

                            <td>{{ failure.status }}</td>
                            <td>{{ failure.location }}</td>

                            <td>{{ failure.notes }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
        </v-col>
    </v-row>

    <report-sensor-failure-modal v-model="showReportAFailureModal" :serial-number="serialNumber" @reported-failure="reportedFailure" />
</template>

<script setup lang="ts">
import { useApi } from '@/api'
import { ref, onMounted, watch } from 'vue'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { handleApiError, requireTimezone } from '@/lib/utils'
import type { GetSensorDetailsV1, SensorFailureV1 } from '@geckoal/gecko-api-client'
import { format } from 'date-fns-tz'
import VesselButton from '@/components/buttons/VesselButton.vue'
import ReportSensorFailureModal from '@/components/sensors/ReportSensorFailureModal.vue'
import MonitorButton from '@/components/buttons/MonitorButton.vue'
import SensorFailureTypeChip from '@/components/sensors/SensorFailureTypeChip.vue'

const breadCrumbsStore = useBreadCrumbsStore()

const timezone = requireTimezone()

const loadingSensorDetails = ref(false)
const sensorDetails = ref<GetSensorDetailsV1 | undefined>()

const loadingSensorFailures = ref(false)
const sensorFailures = ref<SensorFailureV1[] | undefined>()

const showReportAFailureModal = ref(false)

const props = defineProps({
    serialNumber: {
        type: String,
        required: true
    }
})

onMounted(() => {
    _loadData()
})

watch(
    () => props.serialNumber,
    () => {
        _loadData()
    }
)

function _loadData() {
    getSensorDetails()
    getSensorFailures()
    breadCrumbsStore.$patch({
        items: [
            {
                text: 'Sensors'
            },
            {
                text: String(props.serialNumber)
            }
        ]
    })
}

const getSensorDetails = async () => {
    handleApiError(
        async () => {
            loadingSensorDetails.value = true
            sensorDetails.value = await useApi().getSensorDetailsV1({
                serialNumber: props.serialNumber
            })
        },
        async () => {
            loadingSensorDetails.value = false
        }
    )
}

const getSensorFailures = async () => {
    handleApiError(
        async () => {
            loadingSensorFailures.value = true
            const result = await useApi().getSensorFailuresV1({
                serialNumber: props.serialNumber
            })
            sensorFailures.value = result.failures
        },
        async () => {
            loadingSensorFailures.value = false
        }
    )
}

const reportedFailure = () => {
    getSensorFailures()
}
</script>

<style scoped lang="scss"></style>
