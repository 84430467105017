<template>
    <v-card min-height="400">
        <v-row>
            <v-col>
                <v-toolbar density="compact" color="#ccc">
                    <v-btn id="date-menu-activator" color="#FFF">
                        from <span class="text-decoration-underline text-indigo-lighten-1 mx-2">{{ startDateButtonText }}</span>

                        to <span class="text-decoration-underline text-indigo-lighten-1 mx-2">{{ endDateButtonText }}</span>
                        <v-menu v-model="showDateEditor" activator="#date-menu-activator" :close-on-click="false" :close-on-content-click="false">
                            <v-card min-width="300">
                                <v-card-text>
                                    <v-text-field
                                        v-model="editableStartDateText"
                                        label="From"
                                        placeholder="e.g. mm/dd/yy or 2 weeks ago"
                                        :hint="startDateHint"
                                        persistent-hint
                                        :error="startDateError"
                                        full-width
                                        clearable
                                        @keydown.enter="applyDates"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="editableEndDateText"
                                        label="To"
                                        placeholder="e.g. mm/dd/yy or today"
                                        :hint="endDateHint"
                                        persistent-hint
                                        :error="endDateError"
                                        full-width
                                        clearable
                                        @keydown.enter="applyDates"
                                    ></v-text-field>
                                    <v-btn block variant="elevated" color="primary" :disabled="!canSubmit" @click="applyDates">GO</v-btn>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-btn>
                    <v-btn icon size="small" color="light" style="margin-left: 10px" @click="loadCharts"><v-icon>mdi-reload</v-icon></v-btn>
                    <span v-if="bucketSentence">{{ bucketSentence }}</span>
                    <v-spacer></v-spacer>
                    <time-zone-select @change="updateTimeZone" style="margin-left: 10px" />
                    <v-btn icon size="small" color="light" style="margin-left: 10px" @click="downloadCSV">
                        <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                    <v-menu :close-on-content-click="false" location="start top">
                        <template v-slot:activator="{ props }">
                            <v-btn icon v-bind="props" size="small" color="light" style="margin-left: 10px">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-card min-width="300">
                            <v-list>
                                <v-list-item>
                                    <v-switch v-model="largeCharts" color="purple" label="Large Charts" hide-details></v-switch>
                                </v-list-item>
                                <v-divider />
                                <v-list-item v-for="key of Object.keys(filteredVisibleCharts)" :key="key" density="compact">
                                    <v-switch v-model="visibleCharts[key as ChartsKeys]" color="purple" :label="key" hide-details></v-switch>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-overlay persistent v-model="loading" contained class="align-center justify-center">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <charts
            v-if="startDate && endDate"
            :start-date="startDate"
            :end-date="endDate"
            :monitor-averaged="monitorAveraged"
            :visible-charts="visibleCharts"
            :loading="loading"
            :timezone="timezone"
            :large="largeCharts"
        />
    </v-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import * as chrono from 'chrono-node'
import { isAfter, isBefore, isValid, formatDuration } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import TimeZoneSelect from './TimeZoneSelect.vue'
import { Charts, type Timezone, type ChartDataStreams, type VisibleCharts, downloadChartDataStreamsToCSVFile, ChartsKeys } from '@geckoal/gecko-webapp-utils'
import { useRoute } from 'vue-router'
import { CrystalAPIMonitorChartFetcher } from '../lib/CrystalAPIMonitorChartFetcher'
import { GetMonitorChartV3ChartTypeEnum } from '@geckoal/gecko-api-client'
import { handleApiError, requireTimezone } from '@/lib/utils'
import { normalize } from 'duration-fns'
import type { Duration } from 'date-fns'

const route = useRoute()
const monitorChartFetcher = new CrystalAPIMonitorChartFetcher()

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    }
})

function pageDefaults() {
    const startDateString = Array.isArray(route.query.startDate) ? route.query.startDate[0] : route.query.startDate
    const startDate = startDateString ? new Date(startDateString) : undefined

    const endDateString = Array.isArray(route.query.endDate) ? route.query.endDate[0] : route.query.endDate
    const endDate = endDateString ? new Date(endDateString) : undefined

    const startDateText = startDate ? format(startDate, 'MM/dd/yy h:mm a zz') : '2 weeks ago'
    const endDateText = endDate ? format(endDate, 'MM/dd/yy h:mm a zz') : 'now'

    return {
        startDate,
        endDate,
        startDateText,
        endDateText
    }
}

const { startDateText: defaultStartDateText, startDate: defaultStartDate } = pageDefaults()

const timezone = ref<Timezone>(requireTimezone())
const showDateEditor = ref(false)

const startDate = ref<Date | undefined>()
const endDate = ref<Date | undefined>()

const editableStartDateText = ref<string | undefined>(defaultStartDateText)
const editableEndDateText = ref<string | undefined>('now')

const editableStartDate = ref<Date | undefined>(defaultStartDate)
const editableEndDate = ref<Date | undefined>()

const startDateHint = ref<string | undefined>()
const endDateHint = ref<string | undefined>()

const startDateError = ref<boolean>()
const endDateError = ref<boolean>()

const monitorAveraged = ref<ChartDataStreams | undefined>()
const loading = ref(false)
const largeCharts = ref(true)
const source = ref<string | undefined>()
const bucketInterval = ref<Duration | undefined>()

const visibleCharts = ref<VisibleCharts>({
    ph: true,
    orpMv: true,
    waterTempC: true,
    cpuTempC: true,
    batteryMv: true,
    wifiRssi: true,
    flags: true,
    timing: true
})

const bucketSentence = computed<string | undefined>(() => {
    if (!bucketInterval.value) return undefined

    const dur = bucketInterval.value
    if (dur.years === undefined) delete dur.years
    if (dur.months === undefined) delete dur.months
    if (dur.weeks === undefined) delete dur.weeks
    if (dur.days === undefined) delete dur.days
    if (dur.hours === undefined) delete dur.hours
    if (dur.minutes === undefined) delete dur.minutes
    if (dur.seconds === undefined) delete dur.seconds

    return `Each data point is ${formatDuration(normalize(dur))}`
})

const downloadCSV = () => {
    downloadChartDataStreamsToCSVFile(String(props.monitorId), monitorAveraged.value, undefined, undefined, startDate.value, endDate.value)
}

const filteredVisibleCharts = computed<VisibleCharts>(() => {
    const output: VisibleCharts = {}
    for (const key of Object.keys(visibleCharts.value)) {
        const averagedDates = monitorAveraged.value?.dates
        output[key as keyof VisibleCharts] = averagedDates && averagedDates.length > 0
    }
    return output
})

const getMonitorCharts = async () => {
    if (!props.monitorId) throw new Error('You must provide monitorId or accountId and vesselId')

    await handleApiError(
        async () => {
            loading.value = true

            const {
                charts,
                bucketInterval: _bucketInterval,
                source: _source
            } = await monitorChartFetcher.getMonitorChart({
                monitorId: props.monitorId,
                maxDataPoints: 1000,
                startDate: startDate.value,
                endDate: endDate.value,
                chartType: GetMonitorChartV3ChartTypeEnum.Admin
            })

            source.value = _source
            bucketInterval.value = _bucketInterval
            monitorAveraged.value = charts
        },
        async () => {
            loading.value = false
        }
    )
}

watch(
    editableStartDateText,
    () => {
        editableStartDate.value = undefined

        if (!editableStartDateText.value) {
            startDateHint.value = 'required'
            startDateError.value = true
            return
        }

        const parsedDate = chrono.parseDate(editableStartDateText.value) as Date | null
        if (!parsedDate) {
            startDateHint.value = 'invalid date'
            startDateError.value = true
            return
        }

        if (editableEndDate.value && isAfter(parsedDate, editableEndDate.value)) {
            startDateHint.value = 'must be before end date'
            startDateError.value = true
            return
        }

        startDateError.value = false

        const zonedDate = timezone.value ? utcToZonedTime(parsedDate, timezone.value?.zone) : parsedDate
        editableStartDate.value = zonedDate
        startDateHint.value = format(zonedDate, 'MM/dd/yy h:mm a zz', { timeZone: timezone.value?.zone })
    },
    { immediate: true }
)

watch(
    editableEndDateText,
    () => {
        editableEndDate.value = undefined

        if (!editableEndDateText.value) {
            endDateHint.value = 'required'
            endDateError.value = true
            return
        }

        const parsedDate = chrono.parseDate(editableEndDateText.value) as Date | null
        if (!parsedDate) {
            endDateHint.value = 'invalid date'
            endDateError.value = true
            return
        }

        if (editableEndDate.value && isAfter(parsedDate, editableEndDate.value)) {
            endDateHint.value = 'must be before end date'
            endDateError.value = true
            return
        }

        endDateError.value = false

        const zonedDate = timezone.value ? utcToZonedTime(parsedDate, timezone.value?.zone) : parsedDate
        editableEndDate.value = zonedDate
        endDateHint.value = format(zonedDate, 'MM/dd/yy h:mm a zz', { timeZone: timezone.value?.zone })
    },
    { immediate: true }
)

const canSubmit = computed(() => {
    if (!editableStartDate.value) return false
    if (!editableEndDate.value) return false
    if (isBefore(editableEndDate.value, editableStartDate.value)) return false
    return true
})

const applyDates = () => {
    if (!canSubmit.value) return
    startDate.value = editableStartDate.value
    endDate.value = editableEndDate.value
    showDateEditor.value = false

    history.replaceState({}, '', `${route.path}?startDate=${startDate.value?.toISOString() ?? ''}&endDate=${endDate.value?.toISOString() ?? ''}`)

    loadCharts()
}

const loadCharts = () => {
    getMonitorCharts()
}

const startDateButtonText = computed(() => {
    if (!startDate.value) return 'choose'
    if (!isValid(startDate.value)) return 'choose'

    const zonedTime = timezone.value?.zone ? utcToZonedTime(startDate.value, timezone.value.zone) : startDate.value
    return format(zonedTime, 'MM/dd/yy h:mm a zz', { timeZone: timezone.value?.zone })
})

const endDateButtonText = computed(() => {
    if (!endDate.value) return 'choose'
    if (!isValid(endDate.value)) return 'choose'

    const zonedTime = timezone.value?.zone ? utcToZonedTime(endDate.value, timezone.value.zone) : endDate.value
    return format(zonedTime, 'MM/dd/yy h:mm a zz', { timeZone: timezone.value?.zone })
})

onMounted(() => {
    applyDates()
})

// watch(chartStreams, () => {
//     // Update the setting switchs that show and hide streams. Turn them off by default if there's no data
//     for (const key of enumKeys(ChartValueKeys)) {
//         visibleCharts.value[key] = chartStreams.value ? chartStreams.value.dataLengthForKey(key as ChartValueKeys) > 0 : false
//     }
// })

const updateTimeZone = (newTimezone?: Timezone) => {
    if (!newTimezone) throw new Error(`No timezone`)
    timezone.value = newTimezone
}
</script>

<style lang="scss" scoped>
.chartsHeader {
    background-color: #ccc;
    padding: 6px;
}
</style>
