<template>
    <v-row>
        <v-col xs="12" sm="12" md="6" lg="3">
            <h2 class="mb-10">{{ monitorId }}</h2>
        </v-col>
        <v-col class="text-right">
            <!-- <span class="mr-5">
                <included-subscription-button
                    v-if="monitorDetails"
                    :monitor-id="monitorDetails.monitorId"
                    :subscription-grant-status="monitorDetails.subscriptionGrantStatus"
                    @update-monitor="getMonitorDetails"
                />
            </span> -->

            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" title="Actions" density="compact" size="x-large" v-bind="props"></v-btn>
                </template>
                <v-list>
                    <v-list-item button title="Report a Failure" @click="showReportAFailureModal = true">
                        <template v-slot:prepend>
                            <v-icon color="red-darken-2" icon="mdi-message-alert" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item button title="View Chem Check Info" @click="showChemCheckInfoModal = true"></v-list-item>
                    <v-divider></v-divider>
                    <v-list-item button title="CloudWatch Logs" target="_aws" :href="monitorDetails?.cloudWatchLogsUrl">
                        <template v-slot:prepend>
                            <v-icon color="warning" icon="mdi-aws" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item button title="CloudWatch Samples" target="_aws" :href="monitorDetails?.cloudWatchSamplesUrl">
                        <template v-slot:prepend>
                            <v-icon color="warning" icon="mdi-aws" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item
                        button
                        title="View on Core IoT"
                        target="_aws"
                        :href="`https://us-east-1.console.aws.amazon.com/iot/home?region=us-east-1#/thing/${monitorId}`"
                    >
                        <template v-slot:prepend>
                            <v-icon color="warning" icon="mdi-aws" class="mr-2"></v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item button title="Live Samples" :to="{ name: 'MonitorLiveStream', params: { monitorId } }"></v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-snackbar v-model="errorSnackBar" multi-line>{{ errorText }}</v-snackbar>
        </v-col>
    </v-row>

    <div v-if="loadingMonitorDetails" class="text-center mt-10 mb-10">
        <v-progress-circular indeterminate color="blue" size="50"></v-progress-circular>
    </div>
    <div v-else-if="monitorDetails">
        <v-row>
            <v-col md="6">
                <v-card>
                    <v-card-title>General Info</v-card-title>
                    <v-table>
                        <tbody>
                            <tr>
                                <th>Account</th>
                                <td>
                                    <account-button v-if="monitorDetails.owningAccountId" :account-id="monitorDetails.owningAccountId" />
                                </td>
                            </tr>
                            <tr v-if="monitorDetails.vessel">
                                <th>Vessel</th>
                                <td>
                                    <vessel-button :vessel="monitorDetails.vessel" />
                                </td>
                            </tr>
                            <tr>
                                <th>Sensor</th>
                                <td>
                                    <sensor-button
                                        v-if="monitorDetails.sensor"
                                        :sensor="monitorDetails.sensor"
                                        :monitor-id="monitorId"
                                        @sensor-detached="sensorDetached"
                                    />
                                    <v-dialog v-else v-model="showAttachSensor">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="dark" variant="tonal" prepend-icon="mdi-plus">Attach Sensor</v-btn>
                                        </template>
                                        <v-card :min-width="400">
                                            <v-card-title> Search for a sensor to attach </v-card-title>
                                            <v-card-text>
                                                <choose-sensor @picked:sensor="pickedSensor" />
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn color="secondary" @click="showAttachSensor = false">Cancel</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </td>
                            </tr>
                            <tr v-if="monitorDetails.model">
                                <th>Model</th>
                                <td>
                                    {{ monitorDetails.model }}
                                </td>
                            </tr>
                            <tr v-if="monitorDetails.hardwareRevision">
                                <th>Hardware Revision</th>
                                <td>
                                    {{ monitorDetails.hardwareRevision }}
                                </td>
                            </tr>
                            <tr>
                                <th>Firmware Version</th>
                                <td>
                                    {{ monitorDetails.deviceShadow?.state.reported?.$firmwareVersion ?? 'unknown' }}
                                    <v-btn size="small" style="float: right" @click="showSendOTA = true">Update</v-btn>
                                </td>
                            </tr>
                            <tr v-if="monitorDetails.deviceShadow?.state?.reported?.calibrationsV1?.sensor?.serialNumber">
                                <th>Monitor Shadow Sensor Serial</th>
                                <td>
                                    {{ monitorDetails.deviceShadow?.state?.reported?.calibrationsV1?.sensor?.serialNumber }}
                                </td>
                            </tr>
                            <tr>
                                <th>Provisioning Status</th>
                                <td>
                                    <v-chip v-if="monitorDetails.provisioningStatus === 'provisioned'" color="primary">PROVISIONED</v-chip>
                                    <v-chip v-else-if="monitorDetails.provisioningStatus === 'provisioning'" color="warning">PROVISIONING</v-chip>
                                    <v-chip v-else>UNKNOWN</v-chip>
                                    <span v-if="monitorDetails.provisioningStatusUpdatedAt" class="ml-2"
                                        >since {{ format(monitorDetails.provisioningStatusUpdatedAt, 'Pp') }}</span
                                    >
                                </td>
                            </tr>
                            <!-- <tr v-if="monitorDetails.deviceShadow">
                                <th>Device Shadow</th>
                                <td>{{ monitorDetails.deviceShadow }}</td>
                            </tr> -->
                            <tr>
                                <th>Calibrations</th>
                                <td>
                                    <v-btn
                                        v-if="monitorDetails.deviceShadow?.state.reported?.calibrationsV1"
                                        :to="{ name: 'MonitorCalibrations', params: { monitorId } }"
                                        size="x-small"
                                        >View Calibrations</v-btn
                                    >
                                    <v-btn v-else :to="{ name: 'MonitorCalibrations', params: { monitorId } }" size="small">Add Calibrations</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
            <v-col md="6">
                <v-card v-if="monitorDetails.lastSample">
                    <v-card-title
                        >Last Sample
                        {{
                            monitorDetails.lastSample
                                ? ` - ${formatDate(monitorDetails.lastSample.sampleDate)}, (${formatDistanceToNowStrict(monitorDetails.lastSample.sampleDate, {
                                      addSuffix: true
                                  })})`
                                : ''
                        }}</v-card-title
                    >
                    <v-card-text>
                        <v-row>
                            <v-col md="6">
                                <div v-for="reading of lastSampleReadings?.left" :key="reading.key" class="d-flex align-center justify-space-between reading">
                                    <h1>{{ reading.title }}</h1>
                                    <span>{{ reading.value }} {{ reading.unit }}</span>
                                </div>
                            </v-col>
                            <v-col md="6">
                                <div v-for="reading of lastSampleReadings?.right" :key="reading.key" class="d-flex align-center justify-space-between reading">
                                    <h1>{{ reading.title }}</h1>
                                    <span>{{ reading.value }} {{ reading.unit }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <div v-if="loadingMonitorFailures" class="text-center mt-4 mb-4">
            <v-progress-circular indeterminate color="blue" size="50"></v-progress-circular>
        </div>
        <v-row v-else-if="monitorFailures !== undefined && monitorFailures.length > 0" class="mt-8">
            <v-col>
                <v-card>
                    <v-card-title>Reported Failures</v-card-title>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left pl-4">Failure Type</th>

                                <th class="text-left pl-4">Initial Activation Date</th>
                                <th class="text-left pl-4">Failure Start Date</th>
                                <th class="text-left pl-4">Time In Use</th>

                                <th class="text-left pl-4">Status</th>
                                <th class="text-left pl-4">Location</th>

                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(failure, index) in monitorFailures" :key="index">
                                <td>
                                    <monitor-failure-type-chip :failure-type="failure.failureType" />
                                </td>

                                <td>{{ format(failure.initialActivationDate, 'M/d/yy h:mmaaaaa z', { timeZone: timezone.zone }) }}</td>
                                <td>{{ format(failure.failureStartDate, 'M/d/yy h:mmaaaaa z', { timeZone: timezone.zone }) }}</td>
                                <td>{{ failure.timeInUse }}</td>

                                <td>{{ failure.status }}</td>
                                <td>{{ failure.location }}</td>

                                <td>{{ failure.notes }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-8">
            <v-col>
                <monitor-charts v-if="monitorId" :monitorId="monitorId" :timezone="timezone" :visibleCharts="visibleCharts" />
            </v-col>
        </v-row>

        <v-row class="mt-8">
            <v-col>
                <v-card>
                    <v-card-title>Sensor History</v-card-title>
                    <monitor-sensor-history ref="sensorHistory" :monitor-id="monitorId" />
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-8" v-if="monitorJobs?.pending?.length || monitorJobs?.executions?.length">
            <v-col>
                <v-card v-if="monitorJobs?.pending?.length">
                    <v-card-title>Pending Jobs</v-card-title>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left">Job ID</th>
                                <th class="text-left">Status</th>
                                <th class="text-left">Queued</th>
                                <th class="text-left">Started</th>
                                <th class="text-left">Last Updated</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="job in monitorJobs?.pending" :key="job.jobId">
                                <td>{{ job.jobId }}</td>
                                <td>{{ job.status }}</td>
                                <td>{{ job.queuedAtDate ? format(job.queuedAtDate, 'M/d/yy h:mmaaaaa') : undefined }}</td>
                                <td>{{ job.startedAtDate ? format(job.startedAtDate, 'M/d/yy h:mmaaaaa') : undefined }}</td>
                                <td>{{ job.lastUpdatedAtDate ? format(job.lastUpdatedAtDate, 'M/d/yy h:mmaaaaa') : undefined }}</td>
                                <th><v-btn size="small" variant="outlined" color="warning" :href="job.awsJobUrl" target="_aws">View on AWS</v-btn></th>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>

                <v-card class="mt-8" v-if="monitorJobs?.executions?.length">
                    <v-card-title>Job Executions</v-card-title>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left">Job ID</th>
                                <th class="text-left">Status</th>
                                <th class="text-left">Queued</th>
                                <th class="text-left">Started</th>
                                <th class="text-left">Last Updated</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="execution in monitorJobs?.executions" :key="execution.jobId">
                                <td>
                                    <router-link
                                        :to="{
                                            name: 'MonitorJobExecutionDetails',
                                            params: { monitorId, jobId: execution.jobId, executionNumber: execution.executionNumber }
                                        }"
                                        >{{ execution.jobId }}</router-link
                                    >
                                </td>
                                <td>{{ execution.status }}</td>
                                <td>{{ execution.queuedAtDate ? format(execution.queuedAtDate, 'M/d/yy h:mmaaaaa') : undefined }}</td>
                                <td>{{ execution.startedAtDate ? format(execution.startedAtDate, 'M/d/yy h:mmaaaaa') : undefined }}</td>
                                <td>{{ execution.lastUpdatedAtDate ? format(execution.lastUpdatedAtDate, 'M/d/yy h:mmaaaaa') : undefined }}</td>
                                <th><v-btn size="small" variant="outlined" color="warning" :href="execution.awsJobUrl" target="_aws">View on AWS</v-btn></th>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-8">
            <v-col>
                <v-card>
                    <v-card-title>Vessel History</v-card-title>
                    <v-table v-if="monitorDetails && monitorDetails.attachmentHistory && monitorDetails.attachmentHistory?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-left pl-4">Start</th>
                                <th class="text-left pl-4">End</th>
                                <th class="text-left pl-4">Duration</th>
                                <th class="text-left pl-4">Vessel</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(history, index) in monitorDetails.attachmentHistory" :key="index">
                                <td>{{ history.startDate && format(history.startDate, 'Pp') }}</td>
                                <td>{{ history.current ? 'current' : history.endDate && format(history.endDate, 'Pp') }}</td>
                                <td>{{ duration(history) }}</td>
                                <td>
                                    <vessel-button v-if="history.vessel" :vessel="history.vessel" />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <th style="text-align: right">Total Duration</th>
                                <td>{{ totalTimeInWater }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </v-table>
                    <div v-else class="text-center mt-10">No Vessel History<br /><br /></div>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <v-dialog v-model="showSendOTA" width="auto">
        <v-card>
            <v-card-text>
                <send-o-t-a :monitor-id="monitorId" @created-ota="createdOTA"></send-o-t-a>
            </v-card-text>
            <v-card-actions>
                <v-btn color="danger" @click="showSendOTA = false">CANCEL</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <report-monitor-failure-modal v-model="showReportAFailureModal" :monitor-id="monitorId" @reported-failure="reportedFailure" />
    <chem-check-info-modal v-model="showChemCheckInfoModal" :monitor-id="monitorId" />
</template>

<script setup lang="ts">
import { useApi } from '@/api'
import { ref, onMounted, computed, watch } from 'vue'
import '@/components/VesselDisc.vue'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { add, differenceInSeconds, formatDistance, formatDistanceToNowStrict } from 'date-fns'
import { format } from 'date-fns-tz'
import type { GetSensorSearchResultV1, MonitorDetailsV1, MonitorDetailsAttachmentHistoryV1, MonitorFailureV1, MonitorJobsV1 } from '@geckoal/gecko-api-client'
import VesselButton from '@/components/buttons/VesselButton.vue'
import MonitorCharts from '../components/MonitorCharts.vue'
import { readingInfoForReadingType, ReadingIngressCalculator, ReadingTypes, ReadingUnits } from '@geckoal/chem-engine'
import { handleApiError, requireTimezone, formatDate } from '@/lib/utils'
import ChooseSensor from '@/components/sensors/ChooseSensor.vue'
import MonitorSensorHistory from '@/components/monitors/MonitorSensorHistory.vue'
import type { VisibleCharts } from '@geckoal/gecko-webapp-utils'
import SendOTA from '@/components/monitors/SendOTA.vue'
import ReportMonitorFailureModal from '@/components/monitors/ReportMonitorFailureModal.vue'
import SensorButton from '@/components/buttons/SensorButton.vue'
import AccountButton from '@/components/buttons/AccountButton.vue'
import MonitorFailureTypeChip from '@/components/monitors/MonitorFailureTypeChip.vue'
import IncludedSubscriptionButton from '@/components/monitors/IncludedSubscriptionButton.vue'
import ChemCheckInfoModal from '@/components/debug/ViewChemCheckInfoModal.vue'

const breadCrumbsStore = useBreadCrumbsStore()
const timezone = requireTimezone()

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    }
})

const errorSnackBar = ref(false)
const errorText = ref<string | undefined>()
const loadingMonitorDetails = ref(false)
const monitorDetails = ref<MonitorDetailsV1 | undefined>()
const showSendOTA = ref(false)

const loadingMonitorJobs = ref(false)
const monitorJobs = ref<MonitorJobsV1 | undefined>()
const showAttachSensor = ref(false)
const sensorHistory = ref<InstanceType<typeof MonitorSensorHistory>>()

const loadingMonitorFailures = ref(false)
const monitorFailures = ref<MonitorFailureV1[] | undefined>()
const showReportAFailureModal = ref(false)
const showChemCheckInfoModal = ref(false)

const visibleCharts = ref<VisibleCharts>({
    ph: true,
    orpMv: true,
    waterTempC: true,
    cpuTempC: true,
    batteryMv: true,
    wifiRssi: true
})

const lastSampleReadings = computed(() => {
    if (!monitorDetails.value?.lastSample) return
    const readings = ReadingIngressCalculator.buildReadingsMapFromCrystalCheckin({
        ...monitorDetails.value?.lastSample,
        checkinDate: monitorDetails.value?.lastSample.sampleDate
    })

    const left: { value: number | string; unit?: ReadingUnits; key: string; title: string }[] = []
    const right: { value: number | string; unit?: ReadingUnits; key: string; title: string }[] = []

    for (const readingType of Object.keys(readings) as ReadingTypes[]) {
        const reading = readings[readingType]
        if (!reading) continue

        const readingInfo = readingInfoForReadingType(readingType)

        if (readingInfo !== undefined) {
            const arr =
                readingType === ReadingTypes.ph ||
                readingType === ReadingTypes.orp ||
                readingType === ReadingTypes.waterTemp ||
                readingType === ReadingTypes.battery ||
                readingType === ReadingTypes.wifiRssi
                    ? left
                    : right

            arr.push({
                value: reading.value,
                unit: reading.unit,
                title: readingInfo.shortTitle,
                key: readingType
            })
        }
    }

    right.push({
        value: monitorDetails.value?.lastSample.wasCached ? 'Yes' : 'No',
        title: 'Cached',
        key: 'cached'
    })

    right.push({
        value: monitorDetails.value?.lastSample.invalidWaterSample ? 'Yes' : 'No',
        title: 'Invalid',
        key: 'invalid'
    })

    right.push({
        value: monitorDetails.value?.lastSample.noSensor ? 'Yes' : 'No',
        title: 'No Sensor',
        key: 'no-sensor'
    })

    return { left, right }
})

const getMonitorDetails = async () => {
    try {
        loadingMonitorDetails.value = true
        monitorDetails.value = await useApi().getMonitorDetailsV1({ monitorId: props.monitorId })
    } catch (e) {
        console.error('Error fetching monitor details', e)
        if (e instanceof Error) {
            errorText.value = e.message
            errorSnackBar.value = true
        } else {
            errorText.value = JSON.stringify(e)
            errorSnackBar.value = true
        }
    } finally {
        loadingMonitorDetails.value = false
    }
}

const getMonitorFailures = async () => {
    handleApiError(
        async () => {
            loadingMonitorFailures.value = true
            const result = await useApi().getMonitorFailuresV1({
                monitorId: props.monitorId
            })
            monitorFailures.value = result.failures
        },
        async () => {
            loadingMonitorFailures.value = false
        }
    )
}

const getMonitorJobs = async () => {
    try {
        loadingMonitorJobs.value = true
        monitorJobs.value = await useApi().getMonitorJobsV1({ monitorId: props.monitorId })
    } catch (e) {
        console.error('Error fetching monitor jobs', e)
        if (e instanceof Error) {
            errorText.value = e.message
            errorSnackBar.value = true
        } else {
            errorText.value = JSON.stringify(e)
            errorSnackBar.value = true
        }
    } finally {
        loadingMonitorJobs.value = false
    }
}

onMounted(() => {
    _loadData()
})

watch(
    () => props.monitorId,
    () => {
        _loadData()
    }
)

function _loadData() {
    getMonitorDetails()
    getMonitorJobs()
    getMonitorFailures()
    breadCrumbsStore.$patch({
        items: [
            {
                text: 'Monitors'
            },
            {
                text: String(props.monitorId)
            }
        ]
    })
}

const sensorDetached = () => {
    _loadData()
}

const duration = (history: MonitorDetailsAttachmentHistoryV1): string => {
    if (history.current && history.startDate) {
        return formatDistance(new Date(), history.startDate)
    }

    if (history.startDate && history.endDate) {
        return formatDistance(history.endDate, history.startDate)
    }

    return ''
}

const totalTimeInWater = computed(() => {
    if (!monitorDetails.value) return 'n/a'
    if (monitorDetails.value.attachmentHistory) {
        let totalSeconds = 0
        for (const record of monitorDetails.value.attachmentHistory) {
            if (record.current && record.startDate) {
                totalSeconds += differenceInSeconds(new Date(), record.startDate)
            } else if (record.startDate && record.endDate) {
                totalSeconds += differenceInSeconds(record.endDate, record.startDate)
            }
        }

        // This is stupid, but it's the easiest way to get an auto-ranging
        // duration: e.g. 2 days, 2 minutes, etc.
        // I don't see any other way to convert X ms to a human readable duration in date-fns
        const baseDate = new Date()
        const endDate = add(baseDate, { seconds: totalSeconds })
        return formatDistance(baseDate, endDate)
    }
    return ''
})

const updateSensorHistory = () => {
    sensorHistory.value?.fetchHistory()
}

const pickedSensor = async (sensor: GetSensorSearchResultV1) => {
    if (!monitorDetails.value) return
    try {
        await useApi().putMonitorSensorV1({
            monitorId: monitorDetails.value.monitorId,
            putMonitorSensorV1Request: {
                sensor: {
                    serialNumber: sensor.serialNumber,
                    manufacturedDateCode: sensor.manufacturedDate,
                    type: sensor.type
                }
            }
        })
        getMonitorDetails()
        updateSensorHistory()
    } finally {
        showAttachSensor.value = false
    }
}

const createdOTA = () => {
    showSendOTA.value = false
    getMonitorJobs()
}

const reportedFailure = () => {
    getMonitorFailures()
}
</script>

<style scoped lang="scss">
SMALL {
    color: #666;
    margin-left: 10px;
    font-size: 0.9rem;
    font-weight: 300;
}

.reading {
    padding: 10px;
    border: 0.5px solid #ddd;
    min-width: 300px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    h1 {
        font-weight: 700;
        font-size: 30px;
    }
}
</style>
