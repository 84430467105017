<template>
    <v-chip label variant="outlined" style="--v-chip-height: auto" class="mb-1 userChip" :style="{ 'border-color': borderColor }">
        <template v-slot:prepend>
            <v-avatar color="blue" size="x-small" class="mr-2">
                <img v-if="user.pictureUrl" :src="user.pictureUrl" />
                <span v-else class="white-text text-h5">{{ initials }}</span>
            </v-avatar>
        </template>
        <span class="d-flex flex-column">
            <span class="name">{{ userName }}</span>
            <span class="email">{{ user.email }}</span>
        </span>
    </v-chip>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import type { AccountVesselUserV1, AccountUserV1 } from '@geckoal/gecko-api-client'
import { userInitials } from '@/lib/utils'

const props = defineProps({
    user: {
        type: Object as PropType<AccountVesselUserV1 | AccountUserV1>,
        required: true
    },
    borderColor: {
        type: String,
        default: 'rgba(255, 255, 255, 0.5)'
    }
})

const initials = computed<string>(() => {
    if ('name' in props.user) {
        // AccountVesselUserV1
        const [firstName, ...otherNames] = props.user.name.split(/\s+/)
        return userInitials({
            firstName,
            lastName: otherNames?.join(' ') ?? ''
        })
    }

    // AccountUserV1
    return userInitials(props.user)
})

const userName = computed(() => {
    if ('name' in props.user) return props.user.name
    if ('firstName' in props.user) {
        let name = `${props.user.firstName}`
        if (props.user.lastName !== undefined) name += ` ${props.user.lastName}`
        return name
    }
    return 'Unknown'
})
</script>

<style scoped lang="scss">
.name {
    font-weight: 500;
}
.email {
    font-size: 0.8rem;
}
</style>
