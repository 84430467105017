<template>
    <v-dialog v-model="show">
        <v-card>
            <v-card-text>
                <pre>{{ json }}</pre>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { ref, type PropType, watch } from 'vue'

const props = defineProps({
    modelValue: {
        type: Boolean
    },
    json: {
        type: Object as PropType<Record<string, unknown>>,
        required: false
    }
})

const show = ref(props.modelValue)

const emit = defineEmits(['update:modelValue'])

watch(
    () => props.modelValue,
    () => {
        show.value = props.modelValue
    }
)

watch(show, () => {
    emit('update:modelValue', show.value)
})
</script>

<style lang="scss" scoped></style>
