<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel" :min-width="800" :width="1000">
        <v-card>
            <v-card-title>
                <h3>Report a Failure</h3>
            </v-card-title>
            <v-card-text>
                <MonitorFailureForm @form-state-update="formStateUpdate" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" @click="syncVModel(false)" size="large">Cancel</v-btn>
                <v-btn color="red-darken-1" @click="reportFailure" :disabled="failureFormIsInvalid" size="large">Report Failure</v-btn>
            </v-card-actions>
            <v-overlay contained :model-value="reportingFailure" class="align-center justify-center">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { ref } from 'vue'
import { handleApiError } from '@/lib/utils'
import MonitorFailureForm, { type FailureFormData, type FormStateUpdate } from '../monitors/MonitorFailureForm.vue'

const reportingFailure = ref(false)

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    },
    modelValue: {
        type: Boolean
    }
})

const emit = defineEmits<{
    (event: 'reportedFailure'): void
    (event: 'update:modelValue', value: boolean): void
}>()

const failureFormIsInvalid = ref(false)
const failureFormData = ref<FailureFormData | undefined>()

const formStateUpdate = (event: FormStateUpdate) => {
    failureFormIsInvalid.value = event.invalid
    failureFormData.value = event.state
}

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

const reportFailure = async () => {
    if (failureFormIsInvalid.value === true) return

    const failureData = failureFormData.value
    if (failureData === undefined) return
    if (failureData.failureType === undefined) return

    await handleApiError(
        async () => {
            reportingFailure.value = true

            const result = await useApi().putMonitorFailureV1({
                monitorId: props.monitorId,
                putMonitorFailureBodyV1: {
                    ...failureData
                }
            })

            console.log('reported failure', result)
            emit('reportedFailure')
            syncVModel(false)
        },
        async () => {
            reportingFailure.value = false
        }
    )
}
</script>

<style lang="scss" scoped></style>
