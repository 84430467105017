import { defineStore } from 'pinia'

export type GeneralStoreSTate = {
    showEngineeringOptions: boolean
    backgroundColor: string
    globalErrorMessage?: string
}

export const useGeneralStore = defineStore<'general', GeneralStoreSTate>({
    id: 'general',
    state: () => ({
        showEngineeringOptions: false,
        backgroundColor: 'rgb(240, 240, 240)',
        globalErrorMessage: undefined
    })
})

const keydownHandler = (e: KeyboardEvent) => {
    useGeneralStore().$state.showEngineeringOptions = e.key === 'd'
}

const keyupHandler = (e: KeyboardEvent) => {
    if (e.key !== 'd') return
    useGeneralStore().$state.showEngineeringOptions = false
}

;(() => {
    window.removeEventListener('keydown', keydownHandler)
    window.removeEventListener('keyup', keyupHandler)

    window.addEventListener('keydown', keydownHandler)
    window.addEventListener('keyup', keyupHandler)
})()
