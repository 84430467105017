<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel">
        <edit-vessel-form @vesselUpdated="updated" @cancel="cancel" />
    </v-dialog>
</template>

<script lang="ts" setup>
import EditVesselForm from './EditVesselForm.vue'

defineProps({
    modelValue: {
        type: Boolean
    }
})

const emit = defineEmits<{
    (event: 'vesselUpdated'): void
    (event: 'update:modelValue', value: boolean): void
}>()

const cancel = () => {
    syncVModel(false)
}

const updated = () => {
    emit('vesselUpdated')
}

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped></style>
