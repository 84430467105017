<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel" :min-width="800" :width="1000">
        <v-card>
            <v-card-title>
                <h3>Detach Monitor</h3>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col md="4"> Detachment Reason: </v-col>
                        <v-col>
                            <v-btn-toggle v-model="reason" divided variant="outlined" color="primary" mandatory>
                                <v-btn value="accountMovement">Account Movement</v-btn>
                                <v-btn value="monitorFailure">Monitor Failure</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <MonitorFailureForm v-if="reason === 'monitorFailure'" @form-state-update="formStateUpdate" />
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" @click="syncVModel(false)" size="large">Cancel</v-btn>
                <v-btn
                    v-if="reason === 'monitorFailure'"
                    color="red-darken-1"
                    @click="recordFailureAndDetachMonitor"
                    :disabled="failureFormIsInvalid"
                    size="large"
                    >Record Failure and Detach</v-btn
                >
                <v-btn v-else color="red-darken-1" @click="detachMonitor" size="large">Detach</v-btn>
            </v-card-actions>
            <v-overlay contained :model-value="detachingMonitor" class="align-center justify-center">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { ref } from 'vue'
import { handleApiError } from '@/lib/utils'
import MonitorFailureForm, { type FailureFormData, type FormStateUpdate } from '../monitors/MonitorFailureForm.vue'

const reason = ref<'accountMovement' | 'monitorFailure'>('accountMovement')
const detachingMonitor = ref(false)

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    monitorId: {
        type: String,
        required: true
    },
    modelValue: {
        type: Boolean
    }
})

const emit = defineEmits<{
    (event: 'monitorDetached'): void
    (event: 'update:modelValue', value: boolean): void
}>()

const failureFormIsInvalid = ref(false)
const failureFormData = ref<FailureFormData | undefined>()

const formStateUpdate = (event: FormStateUpdate) => {
    failureFormIsInvalid.value = event.invalid
    failureFormData.value = event.state
}

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

const detachMonitor = async () => {
    await handleApiError(
        async () => {
            detachingMonitor.value = true
            const result = await useApi().removeVesselMonitorV2({
                accountId: props.accountId,
                vesselId: props.vesselId
            })
            console.log('detached monitor', result)
            emit('monitorDetached')
            syncVModel(false)
        },
        async () => {
            detachingMonitor.value = false
        }
    )
}

const recordFailureAndDetachMonitor = async () => {
    if (failureFormIsInvalid.value === true) return

    const failureData = failureFormData.value
    if (failureData === undefined) return
    if (failureData.failureType === undefined) return

    await handleApiError(
        async () => {
            detachingMonitor.value = true
            const result = await useApi().putMonitorFailureV1({
                monitorId: props.monitorId,
                putMonitorFailureBodyV1: {
                    ...failureData,
                    detachMonitorFromVessel: true
                }
            })
            console.log('reported failure and detached monitor', result)
            emit('monitorDetached')
            syncVModel(false)
        },
        async () => {
            detachingMonitor.value = false
        }
    )
}
</script>

<style lang="scss" scoped></style>
