import { useGeneralStore } from '@/stores/general'
import { useTimezone } from '@geckoal/gecko-webapp-utils'
import { format } from 'date-fns'
import type { ErrorObject } from '@vuelidate/core'
import { utcToZonedTime, format as formatTZ } from 'date-fns-tz'
import { CustomActionsVersions } from '@geckoal/chem-engine'

export function formatDate(date: Date): string {
    return format(date, 'M/d/yy h:mm a')
}

export function formatDateNoTime(date: Date): string {
    return format(date, 'M/d/yy')
}

export function formatDateForTimezone({
    date,
    formatString = 'MM/dd/yy h:mm aaaa zz',
    timezoneId
}: {
    date: Date
    formatString?: string
    timezoneId?: string
}): string {
    if (timezoneId !== undefined) {
        const zonedDate = utcToZonedTime(date, timezoneId)
        return formatTZ(zonedDate, formatString, { timeZone: timezoneId })
    }
    return format(date, formatString)
}

export function formatDateForVesselTimezone({
    date,
    formatString = 'MM/dd/yy h:mm aaaa zz',
    vesselTimezoneId
}: {
    date: Date
    formatString?: string
    vesselTimezoneId?: string
}): string {
    return formatDateForTimezone({ date, formatString, timezoneId: vesselTimezoneId })
}

export function userInitials(user: { firstName?: string; lastName?: string }) {
    const initials: string[] = []
    if (user.firstName) initials.push(user.firstName.charAt(0))
    if (user.lastName) initials.push(user.lastName.charAt(0))
    if (initials.length === 0) return '?'
    return initials.join('').toUpperCase()
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function isApiResponseError(candidate: any): candidate is Response {
    return candidate instanceof Response
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function isApiNotFoundError(candidate: any): candidate is Response {
    if (!isApiResponseError(candidate)) return false
    return candidate.status === 404
}

export async function handleApiError<T>(func: () => Promise<T>, fin?: () => Promise<void>): Promise<T | undefined> {
    try {
        return await func()
    } catch (error) {
        console.error('handleApiError', error)

        const message = await getErrorMessage(error)

        const generalStore = useGeneralStore()
        generalStore.$patch({
            globalErrorMessage: message
        })
    } finally {
        if (fin) await fin()
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getErrorMessage(error: any): Promise<string> {
    let message = 'Unexpected Error'
    if (isApiResponseError(error)) {
        const bodyMessage = await getResponseErrorMessage(error)
        if (bodyMessage !== undefined) message = bodyMessage
    } else if (error instanceof Error) {
        message = error.message
    } else {
        message = String(error)
    }
    return message
}

export async function getResponseErrorMessage(response: Response): Promise<string | undefined> {
    try {
        const json = await response.json()
        return json.message ?? json.error ?? JSON.stringify(json)
    } catch (_err) {
        return undefined
    }
}

export function requireTimezone() {
    const timezone = useTimezone()
    if (!timezone) throw new Error(`Error getting browser timezone`)
    return timezone
}

export async function copyToClipboard(value: string): Promise<void> {
    if (navigator.clipboard) {
        await navigator.clipboard.writeText(value)
        return
    }

    // text area method
    const textArea = document.createElement('textarea')
    textArea.value = value
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)

    try {
        textArea.focus()
        textArea.select()
        document.execCommand('copy')
    } finally {
        textArea.remove()
    }
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const vuelidateErrors = (model: any): string[] | undefined => {
    if (!model) return
    return model.$errors.map((e: ErrorObject) => e.$message)
}

export function precision(value: number, decimalPlaces = 2): number {
    const p = Math.pow(10, decimalPlaces)
    const n = value * p * (1 + Number.EPSILON)
    return Math.round(n) / p
}

export const CUSTOM_ACTIONS_VERSION = CustomActionsVersions.version5

export const levenshteinDistance = (s: string, t: string): number => {
    if (!s.length) return t.length
    if (!t.length) return s.length

    const arr = []
    for (let i = 0; i <= t.length; i++) {
        arr[i] = [i]
        for (let j = 1; j <= s.length; j++) {
            arr[i][j] = i === 0 ? j : Math.min(arr[i - 1][j] + 1, arr[i][j - 1] + 1, arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1))
        }
    }
    return arr[t.length][s.length]
}

export function titleCase(sentence: string): string {
    return sentence
        .toLowerCase()
        .split(' ')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')
}

type HexDigit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F'

export type HexColor<T extends string> = T extends `#${HexDigit}${HexDigit}${HexDigit}${infer Rest1}`
    ? Rest1 extends ``
        ? T // three-digit hex color
        : Rest1 extends `${HexDigit}${HexDigit}${HexDigit}`
        ? T // six-digit hex color
        : never
    : never

export function interpolateHexColor<T extends string>(color1: HexColor<T>, color2: HexColor<T>, decimal: number): HexColor<T> {
    const ratio = Math.min(Math.max(decimal, 0), 1)

    const rgb1 = hexToRgb(color1)
    const rgb2 = hexToRgb(color2)

    const interpolatedRgb = {
        r: Math.round(rgb1.r + (rgb2.r - rgb1.r) * ratio),
        g: Math.round(rgb1.g + (rgb2.g - rgb1.g) * ratio),
        b: Math.round(rgb1.b + (rgb2.b - rgb1.b) * ratio)
    }

    return rgbToHex<T>(interpolatedRgb.r, interpolatedRgb.g, interpolatedRgb.b)
}

export function hexToRgb<T extends string>(hex: HexColor<T>): { r: number; g: number; b: number } {
    console.log('hexToRgb', hex)
    const bigint = parseInt((hex as string).slice(1), 16)
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255
    }
}

export function rgbToHex<T extends string>(r: number, g: number, b: number): HexColor<T> {
    return ('#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)) as HexColor<T>
}
