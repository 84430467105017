<template>
    <div class="pt-10">
        <v-row>
            <v-col>
                <h1 class="d-flex align-center">
                    Spa Status <small class="ml-3" v-if="spaMacAddress">({{ spaMacAddress }})</small>
                    <v-dialog width="500">
                        <template v-slot:activator="{ props }">
                            <div v-bind="props" class="linkStateIndicator" :class="linkStateIndicatorClass"></div>
                        </template>

                        <template v-slot:default="{ isActive }">
                            <v-card title=" Connection Status" :subtitle="`client id: ${clientId ?? 'generating...'}`">
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <h3>Socket</h3>
                                            <strong class="linkStateText" :class="wssLinkStateIndicatorClass">{{
                                                wssLinkState?.state ?? 'Not Connected'
                                            }}</strong>
                                        </v-col>
                                        <v-col>
                                            <h3>Spa (UDP)</h3>
                                            <strong class="linkStateText" :class="spaLinkStateIndicatorClass">{{
                                                spaLinkState?.state ?? 'Not Connected'
                                            }}</strong>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="spaLinkState?.error">
                                        <v-col>
                                            <p>
                                                error={{ spaLinkState?.error }}; detail=<span>{{ spaLinkState?.detail }}</span
                                                >;
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text="Close" @click="isActive.value = false" />
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </h1>
            </v-col>
        </v-row>
        <v-row v-if="showLoading" class="my-10">
            <v-col class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <v-row v-if="connected && spaState">
            <v-col class="d-flex align-center">
                <v-btn v-for="pump of spaState.pumps" :key="pump.pumpNumber" :color="colorForPumpState(pump)" class="mr-3 mb-3" @click="togglePump(pump)"
                    >{{ pump.title }} - {{ pump.state }}</v-btn
                >
                <v-btn
                    v-if="spaState.waterfall"
                    :color="colorForWaterfallState(spaState.waterfall)"
                    class="mr-3 mb-3"
                    @click="toggleWaterfall(spaState.waterfall)"
                    >{{ spaState.waterfall.title }} - {{ spaState.waterfall.state }}</v-btn
                >
                <v-btn v-for="light of spaState.lights" :key="light.title" :color="colorForLightState(light)" class="mr-3 mb-3" @click="toggleLight(light)">{{
                    light.title
                }}</v-btn>
            </v-col>
            <v-col class="d-flex align-center">
                <v-text-field v-model="setPointF" label="Heater Set Point °F" clearable :disabled="!spaConnected" hide-details class="mr-3" />
                <v-btn @click="updateSetPoint" size="small">UPDATE</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="connected && spaState">
            <v-col>
                <h2>HEATER</h2>
                <v-table class="text-left" density="compact">
                    <tbody>
                        <tr>
                            <th></th>
                            <th>°F</th>
                            <th>°C</th>
                        </tr>
                        <tr v-if="spaState.heater.displayedTempC !== undefined">
                            <th class="yellow">Displayed Temp.</th>
                            <td class="yellow">{{ round(celsiusToFahrenheit(spaState.heater.displayedTempC)) }} °F</td>
                            <td class="yellow">{{ spaState.heater.displayedTempC }} °C</td>
                        </tr>
                        <tr v-else>
                            <th>Displayed Temp.</th>
                            <td>NOT VALID</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Heater SetPoint:</th>
                            <td>{{ round(celsiusToFahrenheit(spaState.heater.setpointC)) }} °F</td>
                            <td>{{ spaState.heater.setpointC }} °C</td>
                        </tr>
                        <tr>
                            <th>Min. SetPoint:</th>
                            <td>{{ round(celsiusToFahrenheit(spaState.heater.minSetpointC)) }} °F</td>
                            <td>{{ spaState.heater.minSetpointC }} °C</td>
                        </tr>
                        <tr>
                            <th>Max. SetPoint:</th>
                            <td>{{ round(celsiusToFahrenheit(spaState.heater.maxSetpointC)) }} °F</td>
                            <td>{{ spaState.heater.maxSetpointC }} °C</td>
                        </tr>
                        <tr>
                            <th>Real SetPoint:</th>
                            <td>{{ round(celsiusToFahrenheit(spaState.heater.realSetpointC)) }} °F</td>
                            <td>{{ spaState.heater.realSetpointC }} °C</td>
                        </tr>
                        <tr>
                            <th>Heater State:</th>
                            <td>{{ spaState.heater.state }}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Spa Temp. Units:</th>
                            <td>{{ spaState.heater.tempUnit }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </v-table>

                <br />
                <h2>WATER CARE</h2>
                <v-table class="text-left" density="compact">
                    <tbody>
                        <tr>
                            <th>Active Water Care Mode:</th>
                            <td>{{ spaState.activeWaterCare }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
            <v-col>
                <h2>REMINDERS</h2>
                <v-table class="text-left" density="compact">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Days Remaining</th>
                            <th>Push Enabled</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="reminder of spaState.reminders" :key="reminder.type">
                            <td>{{ reminder.type }}</td>
                            <td>{{ reminder.daysRemaining }}</td>
                            <td>{{ reminder.pushEnabled }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useVesselStore } from '@/stores/vessel'
import { onMounted, ref, watch, computed, type Ref } from 'vue'
import {
    GeckoSpaWsClient,
    type GeckoLight,
    type GeckoPump,
    type GeckoSpaState,
    type GeckoSpaWsClientLinkState,
    type GeckoWaterfall
} from '@geckoal/gecko-spa-ws-client'
import { celsiusToFahrenheit, fahrenheitToCelsius, round } from '@geckoal/gecko-spa-api'
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'

const vesselStore = useVesselStore()
const geckoSocket = ref<GeckoSpaWsClient | undefined>(undefined)
const sharedToken = import.meta.env.VITE_SHARED_TOKEN ?? '098765'
const socketUrl = ref(import.meta.env.VITE_GECKO_WEBSOCKET_HOST)

const linkState = ref<GeckoSpaWsClientLinkState | undefined>()
const spaState = ref<GeckoSpaState | undefined>()
const inboundMessages = ref<string[]>([])
const outboundMessages = ref<string[]>([])
const setPointF = ref<number | undefined>()
const snackBarText = ref<string | undefined>()
const route = useRoute()
const shouldBeConnected = ref(true)

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})

const breadCrumbsStore = useBreadCrumbsStore()

const vessel = computed(() => {
    return vesselStore.vessel
})

const spaMacAddress = computed(() => {
    console.log('SPA MAC', vessel.value?.geckoSpaMac)
    return vessel.value?.geckoSpaMac
})

const clientId: Ref<string | undefined> = ref()

const user = useAuth0().user
let clientIdNeedsGeneration = true
watch(
    user,
    async (newValue) => {
        if (newValue != undefined && clientIdNeedsGeneration) {
            clientIdNeedsGeneration = false
            if (newValue.email == undefined) throw new Error(`Admin does not have an email address. Check auth0 configuration.`)

            const msgUint8 = new TextEncoder().encode(newValue.email) // encode as (utf-8) Uint8Array
            const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8) // hash the message
            const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string

            // Client Ids need to be exactly 39 chars (no more, no less)
            const baseId = ('IOS-admin-' + hashHex).substring(0, 39)
            clientId.value = baseId.padEnd(39, '0')
        }
    },
    { immediate: true }
)

async function init() {
    _updateBreadCrumb()
    await vesselStore.loadVessel(props.accountId, props.vesselId)
}

onMounted(init)
watch(route, () => {
    if (route.name === 'GeckoSpaStatus') {
        init()
    }
})

watch([spaMacAddress, clientId, shouldBeConnected], (newValue) => {
    const [currentMac, currentClientId, shouldBeConnected] = newValue
    if (currentMac != undefined && currentClientId != undefined && shouldBeConnected === true && connected.value === false) {
        connect(socketUrl.value, currentMac, sharedToken, currentClientId)
    }
})

onBeforeRouteUpdate(() => {
    shouldBeConnected.value = true
})

onBeforeRouteLeave((_to, _from, next) => {
    shouldBeConnected.value = false // required or the watch will reconnect automatically when this component goes to the background
    disconnectFromSpa()
    console.log('DISCONNECTED FROM SPA')
    next()
})

function _updateBreadCrumb() {
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`,
                to: {
                    name: 'AccountDetails',
                    params: {
                        accountId: props.accountId
                    }
                }
            },
            {
                text: vesselStore.vessel?.name ?? `Vessel #${props.vesselId}`,
                to: {
                    name: 'VesselDetails',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: 'Spa Status'
            }
        ]
    })
}

// const showSnackBar = computed(() => {
//     return snackBarText.value !== undefined
// })

const heaterSetPointC = computed(() => {
    return spaState.value?.heater.setpointC
})

const wssLinkState = computed(() => {
    return linkState.value?.wssLink
})

const connected = computed(() => {
    return linkState.value?.wssLink.state === 'connected'
})

const spaLinkState = computed(() => {
    return linkState.value?.spaLink
})

const spaConnected = computed(() => {
    return spaLinkState.value?.state === 'connected'
})

const linkStateIndicatorClass = computed(() => {
    if (wssLinkState.value === undefined) return 'gray'
    if (spaLinkState.value === undefined) return 'gray'

    if (wssLinkState.value?.state === 'disconnected') return 'red'
    if (spaLinkState.value?.state === 'disconnected') return 'red'

    if (wssLinkState.value?.state === 'connecting') return 'orange'
    if (spaLinkState.value?.state === 'connecting') return 'orange'
    return 'green'
})

const wssLinkStateIndicatorClass = computed(() => {
    if (wssLinkState.value === undefined) return 'gray'
    if (wssLinkState.value?.state === 'disconnected') return 'red'
    if (wssLinkState.value?.state === 'connecting') return 'orange'
    return 'green'
})

const spaLinkStateIndicatorClass = computed(() => {
    if (spaLinkState.value === undefined) return 'gray'
    if (spaLinkState.value?.state === 'disconnected') return 'red'
    if (spaLinkState.value?.state === 'connecting') return 'orange'
    return 'green'
})

const showLoading = computed(() => {
    if (geckoSocket.value === undefined) return false
    if (wssLinkState.value?.state === 'connecting') return true
    if (spaLinkState.value?.state === 'connecting') return true
    if (spaState.value === undefined) return true
    return false
})

watch(heaterSetPointC, () => {
    if (heaterSetPointC.value === undefined) return undefined
    setPointF.value = celsiusToFahrenheit(heaterSetPointC.value)
})

const connect = async (url: string, mac: string, token: string, clientId: string) => {
    if (geckoSocket.value !== undefined) {
        await geckoSocket.value.disconnect()
        geckoSocket.value = undefined
    }

    console.log('Connect', mac)

    inboundMessages.value = []
    outboundMessages.value = []

    geckoSocket.value = new GeckoSpaWsClient(url, mac, token, clientId)
    linkState.value = geckoSocket.value.linkState
    geckoSocket.value.onError(socketError)
    geckoSocket.value.onUnauthorized(socketUnauthorized)
    geckoSocket.value.onMessage(socketMessage)
    geckoSocket.value.onSpaStateUpdate(onSpaStateUpdate)
    geckoSocket.value.onLinkStateUpdate(onLinkStateUpdate)

    geckoSocket.value.connect()
}

const socketUnauthorized = () => {
    console.error('CONNECTION ERROR - UNAUTHORIZED', event)
    snackBarText.value = '401 Unauthorized'
    disconnectFromSpa()
}

const onSpaStateUpdate = (newSpaState: GeckoSpaState) => {
    console.log('SPA STATE UPDATE', newSpaState)
    spaState.value = newSpaState
}

const onLinkStateUpdate = (newLinkState: GeckoSpaWsClientLinkState) => {
    console.log('LINK STATE UPDATE', newLinkState)
    linkState.value = newLinkState
}

const socketMessage = (message: { type: string; [key: string]: unknown }) => {
    inboundMessages.value.unshift(JSON.stringify(message))
}

const socketError = (reason: string) => {
    snackBarText.value = reason
}

const disconnectFromSpa = () => {
    spaState.value = undefined
    geckoSocket.value?.disconnect()
    geckoSocket.value = undefined
    inboundMessages.value = []
    outboundMessages.value = []
}

const colorForPumpState = (pump: GeckoPump): string => {
    if (pump.state === 'HIGH') return 'amber-lighten-2'
    if (pump.state === 'LOW') return 'orange-darken-2'
    return 'indigo-darken-3'
}

const colorForWaterfallState = (waterfall: GeckoWaterfall): string => {
    if (waterfall.state === 'ON') return 'amber-lighten-2'
    return 'indigo-darken-3'
}

const colorForLightState = (light: GeckoLight): string => {
    if (light.state === 'ON') return 'amber-lighten-1'
    return 'indigo-darken-3'
}

const togglePump = (pump: GeckoPump) => {
    send({
        type: 'updatePumpState',
        pumpId: pump.id,
        pumpState: pump.nextState
    })
}

const toggleWaterfall = (waterfall: GeckoWaterfall) => {
    if (waterfall.state === 'ON') {
        send({
            type: 'updateWaterfallState',
            state: 'OFF'
        })
    } else {
        send({
            type: 'updateWaterfallState',
            state: 'ON'
        })
    }
}

const toggleLight = (light: GeckoLight) => {
    if (light.state === 'ON') {
        send({
            type: 'updateLightState',
            lightState: 'OFF'
        })
    } else {
        send({
            type: 'updateLightState',
            lightState: 'ON'
        })
    }
}

const updateSetPoint = () => {
    if (setPointF.value === undefined) throw new Error(`not connected`)
    send({
        type: 'updateSetPoint',
        setPointC: fahrenheitToCelsius(setPointF.value)
    })
}

const send = (message: { type: string; [key: string]: unknown }) => {
    if (geckoSocket.value === undefined) throw new Error(`not connected`)
    outboundMessages.value.unshift(JSON.stringify(message))
    geckoSocket.value?.send(message)
}
</script>

<style lang="scss" scoped>
pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    margin-bottom: 10px;

    &:hover {
        background-color: #333;
    }
}

.yellow {
    color: gold;
    font-weight: bolder;
    font-size: 1.2rem;
}

$green: green;
$orange: orange;
$red: red;

.linkStateIndicator {
    width: 30px;
    height: 30px;
    background-color: gray;
    border-radius: 20px;
    display: inline-block;
    margin-left: 16px;

    &.red {
        background-color: $red;
    }

    &.orange {
        background-color: $orange;
    }

    &.green {
        background-color: $green;
    }
}

.linkStateText {
    &.red {
        color: $red;
    }

    &.orange {
        color: $orange;
    }

    &.green {
        color: $green;
    }
}
</style>
