<template>
    <v-card
        color="#385F73"
        theme="dark"
        v-if="vesselHealthInfo.mostRecentFeedback !== undefined || vesselHealthInfo.openTicketCount > 0"
        class="vesselCard accountHealth clickable"
        height="200px"
        :to="{ name: 'AccountDetails', params: { accountId } }"
    >
        <v-card-title>ACCOUNT HEALTH</v-card-title>
        <v-card-text v-if="loadingVesselHealthInfo" class="pt-3 text-center">
            <v-progress-circular indeterminate size="32"></v-progress-circular>
        </v-card-text>
        <v-card-text v-else class="pt-1 text-center">
            <div v-if="vesselHealthInfo.mostRecentFeedback !== undefined">
                <v-rating
                    v-model="vesselHealthInfo.mostRecentFeedback.rating"
                    readonly
                    density="compact"
                    size="large"
                    color="grey-lighten-1"
                    active-color="yellow-accent-4"
                />
                <div class="ratingDate">{{ formatDistanceToNow(vesselHealthInfo.mostRecentFeedback.date, { addSuffix: true }) }}</div>
            </div>
            <v-chip v-if="vesselHealthInfo.openTicketCount > 0" class="mt-5" color="white"
                ><strong>{{ vesselHealthInfo.openTicketCount }} Open Ticket<span v-if="vesselHealthInfo.openTicketCount > 1">s</span></strong></v-chip
            >
        </v-card-text>
    </v-card>
</template>

<script lang="ts" setup>
import { useAccountStore } from '@/stores/account'
import { computed } from 'vue'
import { formatDistanceToNow } from 'date-fns'

defineProps({
    accountId: {
        type: Number,
        required: true
    }
})

const accountStore = useAccountStore()

const loadingVesselHealthInfo = computed(() => {
    return accountStore.loadingVesselAccountHealthInfo
})

const vesselHealthInfo = computed(() => {
    return accountStore.vesselAccountHealthInfo
})
</script>

<style lang="scss">
.accountHealth {
    position: relative;
    .ratingDate {
        position: relative;
        font-size: 12px;
        top: -6px;
    }
}
</style>
