<script lang="ts" setup>
import { useApi } from '@/api'
import type { VesselCompletedActionV2 } from '@geckoal/gecko-api-client'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useVesselStore } from '@/stores/vessel'
import { onMounted, ref } from 'vue'
import { capitalize } from '@geckoal/gecko-webapp-utils'
import { format } from 'date-fns'
import { handleApiError } from '@/lib/utils'
import type { VDataTable } from 'vuetify/components'

const breadCrumbsStore = useBreadCrumbsStore()
const vesselStore = useVesselStore()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})

onMounted(async () => {
    await vesselStore.loadVessel(props.accountId, props.vesselId)
    getActionHistory()
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`,
                to: {
                    name: 'AccountDetails',
                    params: {
                        accountId: props.accountId
                    }
                }
            },
            {
                text: vesselStore.vessel?.name,
                to: {
                    name: 'VesselDetails',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: 'Action History'
            }
        ]
    })
})

const showConfirmDeleteCompletedActionDialog = ref(false)
const completedActionToDelete = ref<VesselCompletedActionV2 | undefined>()
const deletingCompletedAction = ref(false)
const actions = ref<VesselCompletedActionV2[] | undefined>()

async function getActionHistory() {
    const result = await useApi().getVesselActionHistoryV2({ accountId: props.accountId, vesselId: props.vesselId })
    actions.value = result.actions
}

function colorForCompletionType(completionType: 'completed' | 'snoozed'): 'blue' | 'orange' | 'gray' {
    if (completionType === 'completed') return 'blue'
    if (completionType === 'snoozed') return 'orange'
    return 'gray'
}

function deleteCompletedAction() {
    const actionToDelete = completedActionToDelete.value
    if (actionToDelete === undefined) return

    handleApiError(
        async () => {
            deletingCompletedAction.value = true

            await useApi().delVesselCompletedActionV1({ accountId: props.accountId, vesselId: props.vesselId, completionId: actionToDelete.completionId })

            await getActionHistory()
        },
        async () => {
            completedActionToDelete.value = undefined
            showConfirmDeleteCompletedActionDialog.value = false
            deletingCompletedAction.value = false
        }
    )
}

function clickedDeleteButton(action: VesselCompletedActionV2) {
    completedActionToDelete.value = action
    showConfirmDeleteCompletedActionDialog.value = true
}

const headers: typeof VDataTable['headers'][] = [
    {
        align: 'start',
        key: 'createdAt',
        sortable: true,
        title: 'Date'
    },
    {
        align: 'start',
        key: 'completionType',
        sortable: true,
        title: 'Type'
    },
    {
        key: 'reading',
        sortable: false,
        title: 'Reading'
    },
    {
        key: 'target',
        sortable: false,
        title: 'Target'
    },
    {
        key: 'chemical',
        sortable: false,
        title: 'Chemical'
    },
    {
        key: 'dose',
        sortable: false,
        title: 'Dose'
    },
    {
        key: 'expectedChange',
        sortable: false,
        title: 'Expected Change'
    },
    {
        key: 'data',
        sortable: false,
        title: 'Data'
    },
    {
        key: 'actions',
        sortable: false,
        title: ''
    }
]

const expanded = ref([])
const searchTerm = ref<string | undefined>()
</script>

<template>
    <v-container fluid>
        <v-row class="mb-3">
            <v-col md="6">
                <v-text-field v-model="searchTerm" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line></v-text-field>
            </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="actions" v-model:expanded="expanded" :items-per-page="1000">
            <template #bottom></template>

            <template #item.createdAt="{ item }">
                {{ format(item.createdAt, 'M/d/yy h:mmaaaaa') }}
            </template>

            <template #item.completionType="{ item }">
                <v-chip :color="colorForCompletionType(item.completionType)">
                    {{ capitalize(item.completionType) }} <strong class="ml-2">{{ item.actionType }}</strong>
                </v-chip>
            </template>

            <template #item.reading="{ item }">
                <span v-if="item.reading">
                    <span class="amount"
                        >{{ item.reading.value }} <span class="unit">{{ item.reading.unit }}</span></span
                    >
                </span>
            </template>

            <template #item.target="{ item }">
                <span v-if="item.target">
                    <span class="amount"
                        >{{ item.target.value }} <span class="unit">{{ item.target.unit }}</span></span
                    >
                </span>
            </template>

            <template #item.chemical="{ item }">
                <span v-if="item.chemical" class="chemical">
                    <img :src="item.chemical.imageUrl" />
                    <span class="text">
                        {{ item.chemical.name }}
                    </span>
                </span>
            </template>

            <template #item.dose="{ item }">
                <v-tooltip v-if="item.dose?.isStepped === true" location="top" text="This action has been broken into multiple steps">
                    <template v-slot:activator="{ props }">
                        <span class="amount" v-bind="props"
                            >{{ item.dose.amount }} <span class="unit">{{ item.dose.unit }}</span
                            >&nbsp;*</span
                        >
                    </template>
                </v-tooltip>
                <span v-else-if="item.dose !== undefined" class="amount"
                    >{{ item.dose.amount }} <span class="unit">{{ item.dose.unit }}</span></span
                >
            </template>

            <template #item.expectedChange="{ item }">
                <span v-if="item.expectedChange">
                    <span class="amount"
                        >{{ item.expectedChange.amount }} <span class="unit">{{ item.expectedChange.unit }}</span></span
                    >
                </span>
            </template>

            <template #item.data="{ item }">
                <span v-if="item.data !== undefined">
                    <span v-for="key of Object.keys(item.data)" :key="key"
                        ><strong>{{ key }}</strong
                        >: {{ item.data[key] }};
                    </span>
                </span>
            </template>

            <template #item.actions="{ item, toggleExpand, isExpanded }">
                <v-btn icon size="small" color="red" @click="clickedDeleteButton(item)" class="mr-2">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn v-if="item.chosenAction !== undefined && isExpanded(item)" icon="mdi-chevron-up" variant="plain" @click="toggleExpand" />
                <v-btn v-else-if="item.chosenAction !== undefined" icon="mdi-chevron-down" variant="plain" @click="toggleExpand" />
            </template>

            <template #expanded-row="{ item }">
                <tr class="childRow" v-if="item.chosenAction">
                    <td></td>
                    <td>
                        <v-icon icon="mdi-arrow-right-bottom"></v-icon>
                        <v-chip :color="colorForCompletionType(item.chosenAction.completionType)">
                            {{ capitalize(item.chosenAction.completionType) }} <strong class="ml-2">{{ item.chosenAction.actionType }}</strong>
                        </v-chip>
                    </td>
                    <td>
                        <span v-if="item.chosenAction.reading">
                            <span class="amount"
                                >{{ item.chosenAction.reading.value }} <span class="unit">{{ item.chosenAction.reading.unit }}</span></span
                            >
                        </span>
                    </td>
                    <td>
                        <span v-if="item.chosenAction.target">
                            <span class="amount"
                                >{{ item.chosenAction.target.value }} <span class="unit">{{ item.chosenAction.target.unit }}</span></span
                            >
                        </span>
                    </td>
                    <td>
                        <span v-if="item.chosenAction.chemical" class="chemical">
                            <img :src="item.chosenAction.chemical.imageUrl" />
                            <span class="text">
                                {{ item.chosenAction.chemical.name }}
                            </span>
                        </span>
                    </td>
                    <td>
                        <v-tooltip v-if="item.chosenAction.dose?.isStepped === true" location="top" text="This action has been broken into multiple steps">
                            <template v-slot:activator="{ props }">
                                <span class="amount" v-bind="props"
                                    >{{ item.chosenAction.dose.amount }} <span class="unit">{{ item.chosenAction.dose.unit }}</span
                                    >&nbsp;*</span
                                >
                            </template>
                        </v-tooltip>
                        <span v-else-if="item.chosenAction.dose !== undefined" class="amount"
                            >{{ item.chosenAction.dose.amount }} <span class="unit">{{ item.chosenAction.dose.unit }}</span></span
                        >
                    </td>
                    <td>
                        <span v-if="item.chosenAction.expectedChange">
                            <span class="amount"
                                >{{ item.chosenAction.expectedChange.amount }} <span class="unit">{{ item.chosenAction.expectedChange.unit }}</span></span
                            >
                        </span>
                    </td>
                    <td>
                        <span v-if="item.chosenAction.data !== undefined">
                            <span v-for="key of Object.keys(item.chosenAction.data)" :key="key"
                                ><strong>{{ key }}</strong
                                >: {{ item.chosenAction.data[key] }};
                            </span>
                        </span>
                    </td>
                    <td></td>
                </tr>
            </template>
        </v-data-table>
        <!-- 
        <v-table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Reading</th>
                    <th>Target</th>
                    <th>Chemical</th>
                    <th>Dose</th>
                    <th>Target Change</th>
                    <th>Data</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="action in actions" :key="action.completionId">
                    <td>{{ format(action.createdAt, 'M/d/yy h:mmaaaaa') }}</td>
                    <td>
                        <v-chip :color="colorForCompletionType(action.completionType)"
                            >{{ capitalize(action.completionType) }} <strong class="ml-2">{{ action.actionType }}</strong></v-chip
                        >
                    </td>
                    <td>
                        <span v-if="action.reading">
                            <span class="amount"
                                >{{ action.reading.value }} <span class="unit">{{ action.reading.unit }}</span></span
                            >
                        </span>
                    </td>
                    <td>
                        <span v-if="action.target">
                            <span class="amount"
                                >{{ action.target.value }} <span class="unit">{{ action.target.unit }}</span></span
                            >
                        </span>
                    </td>
                    <td>
                        <div v-if="action.chemical" class="chemical">
                            <img :src="action.chemical.imageUrl" />
                            <div class="text">
                                {{ action.chemical.name }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <span v-if="action.dose">
                            <v-tooltip v-if="action.dose.isStepped === true" location="top" text="This action has been broken into multiple steps">
                                <template v-slot:activator="{ props }">
                                    <span class="amount" v-bind="props"
                                        >{{ action.dose.amount }} <span class="unit">{{ action.dose.unit }}</span
                                        >&nbsp;*</span
                                    >
                                </template>
                            </v-tooltip>
                            <span v-else class="amount"
                                >{{ action.dose.amount }} <span class="unit">{{ action.dose.unit }}</span></span
                            >
                        </span>
                    </td>
                    <td>
                        <span v-if="action.expectedChange">
                            <span class="amount"
                                >{{ action.expectedChange.amount }} <span class="unit">{{ action.expectedChange.unit }}</span></span
                            >
                        </span>
                    </td>
                    <td>
                        <span v-if="action.data !== undefined">
                            <span v-for="key of Object.keys(action.data)" :key="key"
                                ><strong>{{ key }}</strong
                                >: {{ action.data[key] }};
                            </span>
                        </span>
                    </td>
                    <td>
                        {{ action.chosenAction }}
                        <v-btn icon size="small" color="red" @click="clickedDeleteButton(action)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table> -->
    </v-container>
    <v-dialog v-model="showConfirmDeleteCompletedActionDialog" width="auto">
        <v-card>
            <v-card-text>
                Are you sure you want to permanently delete this <strong>{{ completedActionToDelete?.actionType }}</strong> action completed on
                <strong v-if="completedActionToDelete">{{ format(completedActionToDelete.createdAt, 'M/d/yy h:mmaaaaa') }}</strong
                >? The action may reappear for the user.
            </v-card-text>
            <v-card-actions>
                <v-btn color="light" @click="showConfirmDeleteCompletedActionDialog = false">No, Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="flat" @click="deleteCompletedAction()" :loading="deletingCompletedAction">Yes, Delete It</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.chemical {
    display: flex;
    flex-direction: row;

    img {
        max-width: 40px;
        height: auto;
        margin-right: 8px;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.childRow {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>
