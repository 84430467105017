<template>
    <div class="pt-10">
        <h3 class="mb-4">Recent Water Reports</h3>
        <v-table class="waterReports" density="comfortable">
            <tr>
                <th></th>
                <th>Type</th>

                <th>Readings</th>
                <th>Other Readings</th>

                <th>Notes</th>
                <th>Errors</th>
                <th>App Info</th>
                <th>Classifications</th>

                <th>Test Strip Info</th>
                <th></th>
            </tr>
            <tr v-for="waterReport of waterReports" :key="waterReport.waterReportId">
                <th>{{ formatDate(waterReport.date) }}</th>
                <td>
                    <water-report-type-chip :water-report-type="waterReport.type" />
                </td>

                <td>
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.Ph"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.Bromine"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.FreeChlorine"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.TotalChlorine"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.SmartChlor"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.TotalAlkalinity"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.TotalHardness"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                    <water-report-history-reading
                        :readings="waterReport.readings"
                        :excluded-measurements="waterReport.excludedMeasurements"
                        :reading-type="ReadingTypesV1.CyanuricAcid"
                        :vessel-timezone-id="vesselTimeZoneId"
                    />
                </td>
                <td>{{ otherReadings(waterReport.readings) }}</td>

                <td>{{ waterReport.notes }}</td>
                <td>
                    <v-tooltip :text="`${error.phase} - ${error.message}`" v-for="error in testStripErrorTags(waterReport)" :key="error.title">
                        <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" :color="error.color">{{ error.title }}</v-chip>
                        </template>
                    </v-tooltip>
                </td>
                <td>
                    {{ waterReport.appPlatform }}<span v-if="waterReport.appPlatform && waterReport.appVersion"> &#8226; </span>{{ waterReport.appVersion }}
                </td>
                <td>
                    <div v-if="waterReport.classifications">
                        <v-chip class="ma-1" color="primary" size="x-small" v-for="key of Object.keys(waterReport.classifications)" :key="key"
                            ><strong>{{ key }}</strong
                            >: {{ precision(waterReport.classifications[key], 2) }}</v-chip
                        >
                    </div>
                </td>
                <td>
                    {{ waterReport.scannedTestStripManufacturer
                    }}<span v-if="waterReport.scannedTestStripManufacturer && waterReport.scannedTestStripName"> &#8226; </span
                    >{{ waterReport.scannedTestStripName }}
                </td>
                <td>
                    <v-btn @click="showWaterReportDetails(waterReport)" size="x-small" variant="text" color="blue">View Details</v-btn>
                </td>
            </tr>
        </v-table>
    </div>
    <v-dialog v-model="showWaterReportDetailsModal" max-width="1000">
        <v-card v-if="focusedWaterReportDetails">
            <v-card-title class="d-flex justify-space-between align-center">
                <div class="text-h5 text-medium-emphasis ps-2">
                    <water-report-type-chip :water-report-type="focusedWaterReportDetails.type" class="mr-3" /> on
                    <strong>{{ formatDate(focusedWaterReportDetails.date) }}</strong>
                </div>
                <v-btn icon="mdi-close" variant="text" @click="showWaterReportDetailsModal = false"></v-btn>
            </v-card-title>

            <v-divider v-if="focusedWaterReportDetails.testStripInfo !== undefined" class="mb-4"></v-divider>

            <v-card-text>
                <v-row>
                    <v-col>
                        <table class="measurementsTable">
                            <tr v-if="focusedWaterReportDetails.testStripInfo?.readings !== undefined">
                                <th>Photo Scan Values</th>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.Ph"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.Bromine"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.FreeChlorine"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.TotalChlorine"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.TotalAlkalinity"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.TotalHardness"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.CyanuricAcid"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.testStripInfo.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.SmartChlor"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="
                                    focusedWaterReportDetails.testStripInfo?.readings !== undefined &&
                                    focusedWaterReportDetails.testStripInfo?.userOverrodeTestStripScan === true
                                "
                            >
                                <th></th>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.Ph)"
                                    />
                                </td>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.Bromine)"
                                    />
                                </td>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.FreeChlorine)"
                                    />
                                </td>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.TotalChlorine)"
                                    />
                                </td>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.TotalAlkalinity)"
                                    />
                                </td>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.TotalHardness)"
                                    />
                                </td>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.CyanuricAcid)"
                                    />
                                </td>
                                <td>
                                    <v-icon
                                        icon="mdi-account-arrow-down"
                                        class="mb-2"
                                        color="red-darken-2"
                                        title="User Override"
                                        v-if="isUserOverride(ReadingTypesV1.SmartChlor)"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="
                                    focusedWaterReportDetails.testStripInfo?.readings !== undefined &&
                                    focusedWaterReportDetails.testStripInfo?.userOverrodeTestStripScan === true
                                "
                            >
                                <th>
                                    <span v-if="focusedWaterReportDetails.type === WaterReportTypesV1.TestStripScan">User Confirmed</span>
                                    <span v-else-if="focusedWaterReportDetails.type === WaterReportTypesV1.TestStripPick">Picked Colors</span>
                                    <span v-else>Manually Entered</span>
                                </th>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.Ph"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.Bromine"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.FreeChlorine"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.TotalChlorine"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.TotalAlkalinity"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.TotalHardness"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.CyanuricAcid"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                                <td>
                                    <water-report-history-reading
                                        :readings="focusedWaterReportDetails.readings"
                                        :excluded-measurements="focusedWaterReportDetails.excludedMeasurements"
                                        :reading-type="ReadingTypesV1.SmartChlor"
                                        :vessel-timezone-id="vesselTimeZoneId"
                                    />
                                </td>
                            </tr>
                        </table>
                    </v-col>
                    <v-col v-if="focusedWaterReportDetails?.testStripInfo !== undefined">
                        <v-row>
                            <v-col class="text-center">
                                <h3>{{ focusedWaterReportDetails.testStripInfo.manufacturer }} &#8226; {{ focusedWaterReportDetails.testStripInfo.name }}</h3>
                                <div>
                                    <small v-if="focusedWaterReportDetails.testStripInfo.processorRequestId"
                                        >Request ID: {{ focusedWaterReportDetails.testStripInfo.processorRequestId }}</small
                                    >
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-if="focusedWaterReportDetails.testStripInfo.errors !== undefined && focusedWaterReportDetails.testStripInfo.errors.length > 0">
                            <v-col>
                                <v-alert
                                    v-for="error in focusedWaterReportDetails.testStripInfo.errors"
                                    :key="error.code"
                                    type="error"
                                    title="Test Strip Scan Error"
                                    :text="`${error.message} [phase=${error.phase}, code=${error.code}]`"
                                ></v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-if="focusedWaterReportDetails.testStripInfo.temporaryImageUrl">
                            <v-col class="d-flex align-center justify-center">
                                <div class="testStripImage">
                                    <img ref="testStripImage" :src="focusedWaterReportDetails.testStripInfo.temporaryImageUrl" />
                                    <v-btn
                                        :href="focusedWaterReportDetails.testStripInfo.temporaryImageUrl"
                                        download
                                        color="secondary"
                                        class="downloadButton ml-4"
                                        title="Download Image"
                                        size="small"
                                        icon="mdi-download"
                                    ></v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-text>
                <v-row>
                    <v-col class="d-flex align-center justify-center">
                        <small class="mr-2">Water Report ID #{{ focusedWaterReportDetails?.waterReportId }}</small
                        ><span v-if="focusedWaterReportDetails?.testStripInfo?.testStripScanId"> &#8226; </span
                        ><small class="ml-2" v-if="focusedWaterReportDetails?.testStripInfo?.testStripScanId"
                            >Test Strip Scan ID #{{ focusedWaterReportDetails?.testStripInfo?.testStripScanId }}</small
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { handleApiError } from '@/lib/utils'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useVesselStore } from '@/stores/vessel'
import { computed, onMounted, ref } from 'vue'
import { formatDate, precision } from '@/lib/utils'
import {
    ReadingTypesV1,
    type ReadingsV1,
    type VesselWaterReportDetailsV1,
    type VesselWaterReportV1,
    WaterReportTypesV1
} from '@geckoal/gecko-api-client'
import WaterReportHistoryReading from '@/components/waterReports/WaterReportHistoryReading.vue'
import WaterReportTypeChip from '@/components/waterReports/WaterReportTypeChip.vue'

const breadCrumbStore = useBreadCrumbsStore()
const vesselStore = useVesselStore()

const loadingWaterReports = ref(false)
const waterReports = ref<VesselWaterReportV1[] | undefined>()
const showWaterReportDetailsModal = ref(false)

const focusedWaterReportDetails = ref<VesselWaterReportDetailsV1 | undefined>()
const testStripImage = ref<HTMLImageElement | undefined>()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})

const vesselTimeZoneId = computed<string | undefined>(() => {
    return vesselStore.vessel?.location?.timeZone?.timeZoneId
})

onMounted(async () => {
    fetchWaterReports()

    await vesselStore.loadVessel(props.accountId, props.vesselId)

    breadCrumbStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`,
                to: {
                    name: 'AccountDetails',
                    params: {
                        accountId: props.accountId
                    }
                }
            },
            {
                text: vesselStore.vessel?.name ?? `Vessel #${props.vesselId}`,
                to: {
                    name: 'VesselDetails',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: 'Water Reports'
            }
        ]
    })
})

const fetchWaterReports = async () => {
    handleApiError(
        async () => {
            loadingWaterReports.value = true
            const result = await useApi().getVesselWaterReportsV1({
                accountId: props.accountId,
                vesselId: props.vesselId
            })
            waterReports.value = result.waterReports
        },
        async () => {
            loadingWaterReports.value = false
        }
    )
}

const testStripErrorTags = (waterReport: VesselWaterReportV1): { color: string; title: string; phase: string; message?: string }[] => {
    if (waterReport.scannedTestStripErrors === undefined) return []
    if (waterReport.scannedTestStripErrors.errors === undefined) return []

    return waterReport.scannedTestStripErrors.errors.map((error) => {
        const color = 'red'
        let title = error.code

        if (error.code === 'NoStrip') {
            title = 'No Strip'
        } else if (error.code === 'WrongStrip') {
            title = 'Wrong Strip'
        } else if (error.code === 'BadPad') {
            title = 'Bad Pad'
        }

        return { color, title, phase: error.phase, message: error.message }
    })
}

const otherReadings = (readings: ReadingsV1): string => {
    const readingsInTables = [
        'ph',
        'bromine',
        'freeChlorine',
        'totalChlorine',
        'totalAlkalinity',
        'totalHardness',
        'cyanuricAcid',
        'smartChlor'
    ] as (keyof ReadingsV1)[]
    const output: string[] = []
    for (const key of Object.keys(readings) as (keyof ReadingsV1)[]) {
        if (readingsInTables.indexOf(key) > -1) continue
        const value = readings[key]
        if (value !== undefined) output.push(`${key}=${value.value} ${value.unit}`)
    }
    return output.join(', ')
}

const showWaterReportDetails = async (waterReport: VesselWaterReportV1) => {
    const result = await useApi().getWaterReportDetailsV1({
        accountId: props.accountId,
        vesselId: props.vesselId,
        waterReportId: waterReport.waterReportId
    })
    focusedWaterReportDetails.value = result
    showWaterReportDetailsModal.value = true
}

const isUserOverride = (key: keyof ReadingsV1) => {
    const waterReportReadings = focusedWaterReportDetails.value?.readings
    if (waterReportReadings === undefined) return false

    const testStripReadings = focusedWaterReportDetails.value?.testStripInfo?.readings
    if (testStripReadings === undefined) return false

    const testStripValue = testStripReadings[key]
    const waterReportValue = waterReportReadings[key]

    if (testStripValue === undefined) return false
    if (waterReportValue === undefined) return false

    return testStripValue.value !== waterReportValue.value
}
</script>

<style lang="scss">
.waterReports {
    padding: 10px;

    TH {
        text-align: left;
    }

    TD {
        padding: 4px;
    }

    TR {
        &:hover {
            background-color: rgba(100, 100, 100, 0.05);
        }
    }
}

.testStripImage {
    max-width: 500px;
    max-height: 600px;
    height: auto;
    position: relative;

    IMG {
        max-width: 500px;
        max-height: 600px;
        height: auto;
    }

    .downloadButton {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

.measurementsTable {
    th {
        text-align: right;
        padding-right: 20px;
    }
    td {
        text-align: center;
    }
}
</style>
