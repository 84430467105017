<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel">
        <v-card :min-width="800">
            <v-card-title>
                <v-row>
                    <v-col>
                        <h3>Add Water Report</h3>
                    </v-col>
                    <v-col class="titleDate">
                        {{ format(reportDateAndTime, 'M/d/yy h:mm a') }}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col><v-text-field label="Report Date" v-model="reportDate" required type="date" @change="verifyDate" /></v-col>
                        <v-col><v-text-field label="Report Time" v-model="reportTime" required type="time" @change="verifyDate" /></v-col>
                        <v-col><v-select v-model="reportType" :items="allReportTypes" label="Report Type"></v-select></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div
                                v-for="reading of addedReadings"
                                :key="reading.key"
                                class="reading"
                                :class="{ hasValue: validReadingValues[reading.key] !== undefined }"
                            >
                                <div class="inner">
                                    <div class="abbreviation">{{ reading.abbreviation }}</div>
                                    <div class="inputWrapper">
                                        <input
                                            type="number"
                                            v-model.number="readingValues[reading.key]"
                                            :min="reading.min"
                                            :max="reading.max"
                                            @keypress="reading.keyPress"
                                            @change="validate(reading.key, reading)"
                                        />
                                        <!-- {{ reading.min }} to {{ reading.max }} -->
                                        <div class="fullname">{{ reading.title }}</div>
                                    </div>
                                    <div class="unit">
                                        {{ reading.unit }}
                                    </div>
                                </div>
                            </div>
                            <div class="addButton" v-if="addableReadings.length > 0">
                                <div class="inner">
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" rounded icon color="primary" alt="Add More Readings"><v-icon>mdi-plus</v-icon></v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="item of addableReadings" :key="item.key" @click="addReading(item.key)">
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-5">
                        <v-col>
                            <v-textarea v-model="notes" label="Notes" hint="Any notes you may want to keep track of" auto-grow rows="2"></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
                <v-overlay :model-value="savingWaterReport" class="align-center justify-center">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-snackbar v-model="showErrorSnackBar" multi-line contained :timeout="4000" color="red-darken-2">
                    {{ snackBarText }}
                    <template v-slot:actions>
                        <v-btn color="white" variant="text" @click="showErrorSnackBar = false"> OK </v-btn>
                    </template>
                </v-snackbar>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" @click="syncVModel(false)">Close</v-btn>
                <v-btn color="blue-darken-1" @click="submitReport" :disabled="validReadingsCount === 0 || savingWaterReport" :loading="savingWaterReport"
                    >Save {{ validReadingsCount }} Readings</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/api'
import { handleApiError } from '@/lib/utils'
import { ReadingTypes, readingInfoForReadingType, tempCToTempF, tempFToTempC } from '@geckoal/chem-engine'
import { type VesselWaterReportInputV1, WaterReportTypesV1 } from '@geckoal/gecko-api-client'
import { format, isAfter, parse } from 'date-fns'
import { computed, ref } from 'vue'

type WaterReportKeys = keyof Omit<VesselWaterReportInputV1, 'date' | 'type' | 'notes'>

type AddableReading = {
    key: WaterReportKeys
    title: string
    abbreviation: string
    unit: string
    min?: number
    max?: number
    keyPress?(evt: KeyboardEvent): void
    convertInput?: (input: number) => { value: number; unit: string }
}

const emit = defineEmits(['waterReportAdded', 'update:modelValue'])

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    modelValue: {
        type: Boolean
    }
})

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

const now = new Date()
const reportDate = ref(format(now, 'yyyy-MM-dd'))
const reportTime = ref(format(now, 'HH:mm'))
const notes = ref<string | undefined>()
const addedReadings = ref<AddableReading[]>([])
const readingValues = ref<Record<string, number | string>>({})
const reportType = ref<WaterReportTypesV1>(WaterReportTypesV1.Manual)
const savingWaterReport = ref(false)
const showErrorSnackBar = ref(false)
const snackBarText = ref<string | undefined>()

const allReportTypes = [
    {
        value: WaterReportTypesV1.Manual,
        title: 'Manual - Self Reported'
    },
    {
        value: WaterReportTypesV1.TestStrip,
        title: 'Test Strip'
    },
    {
        value: WaterReportTypesV1.SpinLab,
        title: 'Spin Lab'
    },
    {
        value: WaterReportTypesV1.Drip,
        title: 'Drip Test - DPD'
    }
]

const ALL_READINGS: AddableReading[] = []

function _addToAllReadings(readingType: ReadingTypes, key: WaterReportKeys, keyPress?: (evt: KeyboardEvent) => void) {
    const readingInfo = readingInfoForReadingType(readingType)
    if (readingInfo === undefined) return

    if (readingType === ReadingTypes.waterTemp) {
        ALL_READINGS.push({
            key,
            abbreviation: readingInfo.abbreviation,
            title: readingInfo.title,
            unit: 'F',
            min: readingInfo.validation?.min !== undefined ? tempCToTempF(readingInfo.validation?.min) : undefined,
            max: readingInfo.validation?.max !== undefined ? tempCToTempF(readingInfo.validation?.max) : undefined,
            keyPress,
            convertInput: (value: number) => {
                return {
                    value: tempFToTempC(value),
                    unit: 'c'
                }
            }
        })
        return
    }

    ALL_READINGS.push({
        key,
        abbreviation: readingInfo.abbreviation,
        title: readingInfo.title,
        unit: readingInfo.unit,
        min: readingInfo.validation?.min,
        max: readingInfo.validation?.max,
        keyPress
    })
}

_addToAllReadings(ReadingTypes.ph, 'ph', isNumber)
_addToAllReadings(ReadingTypes.freeChlorine, 'fcPpm', isNumber)
_addToAllReadings(ReadingTypes.totalChlorine, 'tcPpm', isNumber)
_addToAllReadings(ReadingTypes.bromine, 'brPpm', isNumber)
_addToAllReadings(ReadingTypes.cyanuricAcid, 'cyaPpm', isInteger)
_addToAllReadings(ReadingTypes.orp, 'orpMv', isInteger)
_addToAllReadings(ReadingTypes.totalDissolvedSolids, 'tdsPpm', isInteger)
_addToAllReadings(ReadingTypes.phosphates, 'phosphatesPpm', isInteger)
_addToAllReadings(ReadingTypes.totalHardness, 'thPpm', isInteger)
_addToAllReadings(ReadingTypes.totalAlkalinity, 'taPpm', isInteger)
_addToAllReadings(ReadingTypes.salt, 'saltPpm', isInteger)
_addToAllReadings(ReadingTypes.waterTemp, 'waterTempC', isNumber)
_addToAllReadings(ReadingTypes.smartChlor, 'scPpm', isNumber)

const ALL_READINGS_BY_KEY = ALL_READINGS.reduce((collector, reading) => {
    collector[reading.key] = reading
    return collector
}, {} as Record<WaterReportKeys, AddableReading>)

function addReading(key: WaterReportKeys) {
    const reading = ALL_READINGS_BY_KEY[key]
    if (reading) addedReadings.value.push(reading)
}

function addDefaultReadings() {
    addReading('ph')
    addReading('fcPpm')
    addReading('taPpm')
    addReading('thPpm')
    addReading('cyaPpm')
    addReading('waterTempC')
}
addDefaultReadings()

function resetToDefaultReadings() {
    addedReadings.value = []
    addDefaultReadings()
}

const INTEGER_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
const NUMBER_CHARS = [...INTEGER_CHARS, '.']

function validate(key: string, reading: AddableReading) {
    let value = readingValues.value[key]

    if (typeof value === 'string') {
        if (value.trim() === '') return
        value = Number(value)
    }

    if (reading.min !== undefined && value < reading.min) readingValues.value[key] = reading.min
    if (reading.max !== undefined && value > reading.max) readingValues.value[key] = reading.max
}

function isNumber(evt: KeyboardEvent): void {
    if (!NUMBER_CHARS.includes(evt.key)) evt.preventDefault()
}

function isInteger(evt: KeyboardEvent): void {
    if (!INTEGER_CHARS.includes(evt.key)) evt.preventDefault()
}

// Vue will put '' in the value map when a number field is cleared. This function
// just ignores those and returns only number values
const validReadingValues = computed(() => {
    const output: Record<string, number> = {}
    for (const key of Object.keys(readingValues.value) as WaterReportKeys[]) {
        const value = readingValues.value[key]
        const config = ALL_READINGS_BY_KEY[key]
        if (value !== '' && value !== undefined) {
            if (config.convertInput) {
                const convertedValue = config.convertInput(Number(value))
                output[key] = convertedValue.value
            } else {
                output[key] = Number(value)
            }
        }
    }
    return output
})

const validReadingsCount = computed(() => {
    return Object.keys(validReadingValues.value).length
})

// TODO: optimize?
const addableReadings = computed(() => {
    const output: AddableReading[] = []
    for (const reading of ALL_READINGS) {
        if (keyIsAdded(reading.key)) continue
        output.push(reading)
    }
    return output
})

function keyIsAdded(key: WaterReportKeys): boolean {
    for (const reading of addedReadings.value) {
        if (reading.key === key) return true
    }
    return false
}

const reportDateAndTime = computed(() => {
    return parse(`${reportDate.value} ${reportTime.value}`, 'yyyy-MM-dd HH:mm', now)
})

const verifyDate = () => {
    const enteredDate = reportDateAndTime.value
    if (isAfter(enteredDate, new Date())) {
        reportDate.value = format(new Date(), 'yyyy-MM-dd')
        reportTime.value = format(new Date(), 'HH:mm')
        snackBarText.value = 'You cannot choose a future date'
        showErrorSnackBar.value = true
    }
}

const submitReport = async () => {
    const values = validReadingValues.value

    handleApiError(
        async () => {
            savingWaterReport.value = true

            await useApi().putVesselWaterReportV2({
                accountId: props.accountId,
                vesselId: props.vesselId,
                vesselWaterReportInputV2: {
                    ...values,
                    date: reportDateAndTime.value,
                    type: reportType.value,
                    notes: notes.value
                }
            })

            syncVModel(false)
            resetToDefaultReadings()
            readingValues.value = {}
            notes.value = undefined
            emit('waterReportAdded')
        },
        async () => {
            savingWaterReport.value = false
        }
    )
}
</script>

<style lang="scss" scoped>
$readingHeight: 70px;

.titleDate {
    text-align: right;
    color: #555;
}

.addButton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0 20px 6px 0;
    height: $readingHeight;
    padding: 0 6px;

    .inner {
        height: $readingHeight;
        display: flex;
        align-items: center;
    }
}

.reading {
    display: inline-block;
    position: relative;
    background-color: #eaeaea;
    border-radius: 60px;
    overflow: hidden;
    padding: 0;
    margin: 0 20px 6px 0;
    height: $readingHeight;

    .inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .abbreviation {
        height: $readingHeight;
        min-width: 100px;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        color: #888;
        text-align: right;
        padding: 0 14px 0 18px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .inputWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 8px;
    }

    input {
        display: inline-block;
        color: #333;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 40px;
        line-height: 32px;
        font-weight: 500;
        text-align: center;
        width: 100%;
        min-width: 90px;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            outline: 0;
        }
    }

    .fullname {
        color: #555;
        font-size: 11px;
        text-align: center;
    }

    .unit {
        color: #555;
        padding: 0 10px;
        min-width: 60px;
        text-align: left;
        padding-left: 8px;
    }

    &.hasValue {
        .abbreviation {
            background-color: #2571ff;
            color: white;
        }
    }
}
</style>
