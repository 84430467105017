import { defineStore } from 'pinia'
import type { RouteLocationRaw } from 'vue-router'

export type RouteLocation = {
    name: string
    params: Record<string, unknown>
}

export type BreadcrumbItem = {
    text: string
    disabled?: boolean
    to?: RouteLocationRaw
    exact?: boolean
}

export const useBreadCrumbsStore = defineStore({
    id: 'breadCrumbs',
    state: () => ({
        items: [] as BreadcrumbItem[]
    })
})
