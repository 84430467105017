<template>
    <v-select
        v-model="model"
        label="Vessel Type"
        :items="ALL_VESSEL_TYPES"
        @update:modelValue="syncVModel"
        item-title="title"
        item-value="id"
        @blur="$emit('blur')"
    ></v-select>
</template>

<script lang="ts" setup>
import { ref, type PropType, watch } from 'vue'
import { ALL_VESSEL_TYPES } from '../../lib/vesselTypes'
import type { VesselTypes } from '@geckoal/gecko-api-client'

const props = defineProps({
    modelValue: {
        type: String as PropType<VesselTypes>
    }
})

const model = ref<VesselTypes | undefined>()

watch(
    () => props.modelValue,
    (value) => {
        model.value = value
    },
    { immediate: true }
)

const emit = defineEmits(['vessel-added', 'update:modelValue', 'blur'])

const syncVModel = () => {
    emit('update:modelValue', model.value)
}
</script>
