<template>
    <v-card
        v-if="vessel?.accountUsers?.length"
        color="#FB8C00"
        theme="dark"
        class="vesselCard accountUsersCard clickable"
        height="200px"
        :to="{ name: 'AccountDetails', params: { accountId } }"
    >
        <v-card-title>ACCOUNT USERS</v-card-title>
        <v-card-text class="pt-1">
            <div v-for="accountUser of vessel.accountUsers" :key="accountUser.userId">
                <user-chip :user="accountUser" />
            </div>
        </v-card-text>
    </v-card>
</template>

<script lang="ts" setup>
import { useVesselStore } from '@/stores/vessel'
import { computed } from 'vue'
import UserChip from '@/components/users/UserChip.vue'

defineProps({
    accountId: {
        type: Number,
        required: true
    }
})
const vesselStore = useVesselStore()

const vessel = computed(() => {
    return vesselStore.vessel
})
</script>

<style lang="scss" scoped>
.accountUsersCard {
    font-size: 1.1em;
}

.accountUser {
    border-color: rgba(255, 255, 255, 0.5);
    width: 100%;
}
</style>
