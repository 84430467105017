<template>
    <div>
        <p>Choose an OTA version to send to the monitor</p>
        <div v-if="loadingOTAOptions" class="m-4">
            <v-progress-circular indeterminate size="24"></v-progress-circular>
        </div>
        <v-list
            v-else-if="!loadingOTAOptions && otaOptions && otaOptions.length > 0"
            v-for="otaOption of otaOptions"
            :key="otaOption.version"
            density="compact"
        >
            <v-list-item :title="`v${otaOption.version}`" :subtitle="subTitle(otaOption)" color="primary" @click="sendOTAJob(otaOption.version)">
                <template v-slot:append v-if="otaOption.current">
                    <v-icon icon="mdi-star-circle" color="green"></v-icon>
                </template>
            </v-list-item>
        </v-list>
        <div v-else>No OTA Options Available</div>
    </div>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import type { OTAOptionV1 } from '@geckoal/gecko-api-client'
import { format } from 'date-fns'
import { onMounted } from 'vue'
import { ref } from 'vue'

const loadingOTAOptions = ref(false)
const otaOptions = ref<OTAOptionV1[]>()

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    }
})

const emit = defineEmits<{
    (event: 'created-ota', jobId: string): void
}>()

onMounted(() => {
    loadOTAOptions()
})

const subTitle = (otaOption: OTAOptionV1) => {
    const segments: string[] = []
    if (otaOption.production) segments.push('PRODUCTION')
    segments.push(`published ${format(otaOption.date, 'M/d/yy')}`)
    if (otaOption.releaseNotes && otaOption.releaseNotes.length > 0) segments.push(otaOption.releaseNotes)
    return segments.join('; ')
}

const loadOTAOptions = async () => {
    try {
        loadingOTAOptions.value = true
        const result = await useApi().getOTAOptionsV1({ monitorId: props.monitorId })
        otaOptions.value = result.options
    } finally {
        loadingOTAOptions.value = false
    }
}

const sendOTAJob = async (version: string) => {
    try {
        loadingOTAOptions.value = true

        const result = await useApi().putOTAV1({
            monitorId: props.monitorId,
            putOTARequestV1: {
                version
            }
        })

        console.log('SENT OTA JOB', result.jobId)
        emit('created-ota', result.jobId)
    } finally {
        loadingOTAOptions.value = false
    }
}
</script>

<style lang="scss" scoped></style>
