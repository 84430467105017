<template>
    <v-btn
        :to="{ name: 'MonitorDetails', params: { monitorId }, query: { startDate: startDateString, endDate: endDateString } }"
        :size="size"
        :density="density"
        :color="color"
        :variant="variant"
        title="View Monitor Details"
        :target="target"
        :append-icon="showOpenInIcon && target !== undefined ? 'mdi-open-in-new' : undefined"
    >
        Monitor #{{ monitorId }}</v-btn
    >
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    },
    startDate: {
        type: Object as PropType<Date>
    },
    endDate: {
        type: Object as PropType<Date>
    },
    size: {
        type: String as PropType<'x-small' | 'small' | 'large' | 'x-large'>,
        default: 'large'
    },
    density: {
        type: String as PropType<'compact' | 'comfortable'>
    },
    variant: {
        type: String as PropType<'elevated' | 'flat' | 'tonal' | 'outlined' | 'text' | 'plain'>,
        default: 'tonal'
    },
    color: {
        type: String,
        default: 'primary'
    },
    target: {
        type: String
    },
    showOpenInIcon: {
        type: Boolean,
        default: false
    }
})

const startDateString = computed(() => {
    return props.startDate?.toISOString()
})

const endDateString = computed(() => {
    return props.endDate?.toISOString()
})
</script>
