<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel" :min-width="300" :max-width="1000">
        <v-card>
            <v-card-text class="text-center actionDetails">
                <v-overlay v-if="loadingFocusedAction" class="align-center justify-center">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <div v-else-if="focusedAction">
                    <v-row>
                        <v-col class="text-left">
                            <h1>{{ focusedAction.title }}</h1>
                        </v-col>
                        <v-col class="text-right">
                            <action-trigger-type-chip :trigger="focusedAction.triggerType" />
                        </v-col>
                    </v-row>

                    <v-row v-if="focusedAction?.adjustment">
                        <v-col>
                            <div class="adjustmentTable">
                                <div class="adjustmentCell">
                                    <h4>{{ focusedAction.adjustment.current.title }}</h4>
                                    <h2>{{ focusedAction.adjustment.current.value }}</h2>
                                    <h5>{{ focusedAction.adjustment.current.unit }}</h5>
                                </div>
                                <div class="adjustmentCell centerAdjustmentCell">
                                    <h4>{{ focusedAction.adjustment.needed.title }}</h4>
                                    <h2>{{ focusedAction.adjustment.needed.value }}<sup v-if="focusedAction.recommendation?.stepCaption">*</sup></h2>
                                    <h5>{{ focusedAction.adjustment.needed.unit }}</h5>
                                </div>
                                <div class="adjustmentCell">
                                    <h4>{{ focusedAction.adjustment.target.title }}</h4>
                                    <h2>{{ focusedAction.adjustment.target.value }}</h2>
                                    <h5>{{ focusedAction.adjustment.target.unit }}</h5>
                                </div>
                            </div>
                            <div v-if="focusedAction.recommendation?.stepCaption">
                                <p class="adjustmentCaption"><sup>*</sup>{{ focusedAction.recommendation?.stepCaption }}</p>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row v-if="focusedAction?.adjustment">
                        <v-col>
                            <v-card v-if="focusedAction.recommendation" variant="outlined" class="recommendation">
                                <v-tabs v-show="showTabs" v-model="selectedOptionIndex" color="primary" align-tabs="center">
                                    <v-tab v-for="tab in tabs" :key="tab.index" :value="tab.indexString">
                                        {{ tab.title }}
                                    </v-tab>
                                </v-tabs>
                                <v-card-text>
                                    <h2 class="mb-3">{{ selectedOption?.doseSentence }}</h2>
                                    <v-img
                                        v-if="focusedAction.recommendation.chemical.imageUrl"
                                        :src="focusedAction.recommendation.chemical.imageUrl"
                                        :max-height="300"
                                        class="chemicalImage"
                                    />
                                    <h3>{{ focusedAction.recommendation.chemical.name }}</h3>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row v-if="focusedAction.instructions" class="text-left">
                        <v-col>
                            <ul>
                                <li v-for="(instruction, index) of focusedAction.instructions" :key="index">{{ instruction.text }}</li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row v-if="focusedAction.actionType === ActionType.Survey">
                        <v-col>
                            <v-card>
                                <v-card-title>Questions</v-card-title>
                                <v-card-text v-for="question in focusedAction?.data?.survey.questions" :key="question.id">{{ question.prompt }}</v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row v-else-if="dataItems?.length">
                        <v-col>
                            <v-card>
                                <v-card-title>Action Data</v-card-title>
                                <v-card-text>
                                    <v-data-table v-if="dataItems" :items="dataItems" :headers="dataHeaders" :disable-pagination="true">
                                        <template #headers></template>
                                        <template #bottom></template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue-darken-1" @click="syncVModel(false)">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { CUSTOM_ACTIONS_VERSION, handleApiError } from '@/lib/utils'
import { ActionType, DoseTypeV1, type ActionCompletionOptionV1Schema, type VesselActionDetailsV3 } from '@geckoal/gecko-api-client'
import { computed, ref, watch, type PropType } from 'vue'
import ActionTriggerTypeChip from '@/components/actions/ActionTriggerTypeChip.vue'

const loadingFocusedAction = ref(false)
const focusedAction = ref<VesselActionDetailsV3>()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    actionType: {
        type: String as PropType<ActionType>,
        required: false
    },
    modelValue: {
        type: Boolean
    }
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
}>()

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue === true) {
            loadAction()
        }
    }
)

const loadAction = async () => {
    handleApiError(
        async () => {
            if (props.actionType === undefined) throw new Error(`No action type chosen`)

            loadingFocusedAction.value = true
            focusedAction.value = await useApi().getVesselActionDetailsV5({
                accountId: props.accountId,
                vesselId: props.vesselId,
                actionType: props.actionType,
                customActionsVersion: CUSTOM_ACTIONS_VERSION
            })
        },
        async () => {
            loadingFocusedAction.value = false
        }
    )
}

const completionOptions = computed(() => {
    return focusedAction.value?.recommendation?.completionOptions ?? []
})

const showTabs = computed(() => {
    const optionCount = focusedAction.value?.recommendation?.completionOptions.length ?? 0
    return optionCount > 1
})

interface TabContent {
    index: number
    indexString: string
    title: string
}

const tabs = computed<TabContent[]>(() => {
    const output: TabContent[] = []
    if (completionOptions.value) {
        completionOptions.value.forEach((value, index) => {
            output.push({
                index,
                indexString: index.toString(),
                title: titleForCompletionOption(value)
            })
        })
    }
    return output
})

const titleForCompletionOption = (option: ActionCompletionOptionV1Schema): string => {
    if (option.dose?.doseType === DoseTypeV1.MeasuredVolume) {
        return 'By Volume'
    }
    if (option.dose?.doseType === DoseTypeV1.MeasuredWeight) {
        return 'By Weight'
    }
    if (option.dose?.doseType === DoseTypeV1.WholeUnit) {
        return 'By Unit'
    }
    return 'By Other'
}

const selectedOptionIndex = ref<string | undefined>(undefined)
const selectedOption = computed<ActionCompletionOptionV1Schema | undefined>(() => {
    if (selectedOptionIndex.value === undefined) return undefined

    const index = parseInt(selectedOptionIndex.value)
    return completionOptions.value[index]
})

const dataItems = computed(() => {
    if (focusedAction.value?.data === undefined) return undefined

    if (props.actionType === ActionType.WaterReport) {
        const { requiredReadingTypes, recommendedReadingTypes, otherReadingTypes, ...others } = focusedAction.value.data
        // Shorten these for water reports so the JSON doesn't fill the whole UI
        return Object.entries({
            ...others,
            requiredReadingTypes: (requiredReadingTypes as { title: string }[]).map((rt) => rt.title).join(', '),
            recommendedReadingTypes: (recommendedReadingTypes as { title: string }[]).map((rt) => rt.title).join(', '),
            otherReadingTypes: (otherReadingTypes as { title: string }[]).map((rt) => rt.title).join(', ')
        }).map(([key, value]) => {
            return { key, value }
        })
    }

    return Object.entries(focusedAction.value.data).map(([key, value]) => {
        return { key, value }
    })
})

const dataHeaders = [
    { title: 'Key', value: 'key' },
    { title: 'Value', value: 'value' }
]
</script>

<style lang="scss" scoped>
.adjustmentTable {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;

    h4 {
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-size: 14px;
        color: #555;
        margin: 0 0 10px 0;
    }

    h2 {
        display: inline-block;
        position: relative;
        font-size: 22px;
        font-weight: 600;
        margin: 0;
        color: #555;
    }

    h5 {
        margin: -2px 0 0 0;
        font-size: 13px;
        color: #aaa;
        font-weight: 400;
    }

    .adjustmentCell {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .centerAdjustmentCell {
        h2 {
            font-size: 30px;
            color: #222;
        }
    }
}
</style>
