<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel" max-width="1000">
        <v-card :min-width="800">
            <v-card-title>
                <h3>{{ title }}</h3>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="searchTerm" label="Search by Barcode or Product Name" hide-details clearable></v-text-field>
                        </v-col>
                    </v-row>
                    <div v-if="searchTerm !== undefined && searchTerm !== null && searchTerm.length > 0">
                        <v-row v-if="loadingSearchResults">
                            <v-col>LOADING</v-col>
                        </v-row>
                        <v-row v-else-if="searchResults !== undefined && searchResults.length > 0">
                            <v-col>
                                <v-list>
                                    <v-list-item
                                        v-for="result of searchResults"
                                        :key="result.id.accountChemicalId ?? result.id.systemChemicalId"
                                        @click="chooseChemical(result)"
                                        :disabled="!result.supported"
                                    >
                                        <template v-slot:prepend>
                                            <v-img :src="result.imageUrl" class="chemicalImage mr-3" />
                                        </template>

                                        <v-list-item-title>{{ result.name }}</v-list-item-title>
                                        <v-list-item-subtitle
                                            >{{ result.chemicalForm }} {{ formatIngredientName(result)
                                            }}<span v-if="result.chemicalPercentage !== undefined"
                                                >&nbsp;{{ result.chemicalPercentage }}%</span
                                            ></v-list-item-subtitle
                                        >
                                        <v-list-item-subtitle class="mt-1">{{ formatBarcode(result) }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="mt-1">
                                            <v-chip v-if="result.supported" color="green" size="x-small" density="comfortable" variant="flat">SUPPORTED</v-chip>
                                            <v-chip v-else color="red" size="x-small" density="comfortable" variant="flat">NOT SUPPORTED</v-chip>
                                        </v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col> No Results for '{{ searchTerm }}' </v-col>
                        </v-row>
                    </div>
                    <v-row v-else-if="recommendedChemicals && recommendedChemicals.length">
                        <v-col>
                            <v-list>
                                <v-list-item
                                    v-for="chemical of recommendedChemicals"
                                    :key="chemical.id.accountChemicalId ?? chemical.id.systemChemicalId"
                                    @click="chooseChemical(chemical)"
                                >
                                    <template v-slot:prepend>
                                        <v-img :src="chemical.imageUrl" class="chemicalImage mr-3" />
                                    </template>

                                    <v-list-item-title>{{ chemical.name }}</v-list-item-title>
                                    <v-list-item-subtitle
                                        >{{ chemical.chemicalForm }} {{ formatIngredientName(chemical)
                                        }}<span v-if="chemical.chemicalPercentage !== undefined"
                                            >&nbsp;{{ chemical.chemicalPercentage }}%</span
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="mt-1">{{ formatBarcode(chemical) }}</v-list-item-subtitle>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
                <v-overlay :model-value="loading" class="align-center justify-center">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-card-text>
            <v-card-actions>
                <v-btn color="gray-darken-1" @click="syncVModel(false)">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { ref, type PropType } from 'vue'
import type { ChemicalSearchResultV2, ChemicalUsesV1, ChemicalV2 } from '@geckoal/gecko-api-client'
import { handleApiError } from '@/lib/utils'
import { useApi } from '@/api'
import { onMounted } from 'vue'
import { watch } from 'vue'
import { formatBarcode, formatIngredientName } from '@/lib/chemicals'
import { titleCase } from '@/lib/utils'
import { computed } from 'vue'

const props = defineProps({
    modelValue: {
        type: Boolean
    },
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    chemicalUse: {
        type: String as PropType<ChemicalUsesV1>,
        required: true
    },
    chemicalUseShortName: {
        type: String,
        required: false
    }
})

const loading = ref(false)
const searchTerm = ref<string | undefined | null>()

const recommendedChemicals = ref<ChemicalV2[] | undefined>()
const searchResults = ref<ChemicalSearchResultV2[] | undefined>()
const loadingSearchResults = ref(false)

const title = computed(() => {
    if (props.chemicalUseShortName) return `Choose a ${titleCase(props.chemicalUseShortName)} Chemical`
    return `Choose a Chemical`
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
    (event: 'didChooseChemical', chemicalUse: ChemicalUsesV1, chemical: ChemicalV2): void
}>()

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

watch(
    () => props.chemicalUse,
    () => {
        getChemicalRecommendations()
    }
)

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue === false) {
            searchTerm.value = undefined
            loading.value = false
            recommendedChemicals.value = undefined
            searchResults.value = undefined
            loadingSearchResults.value = false
        }
    }
)

const getChemicalRecommendations = () => {
    handleApiError(
        async () => {
            loading.value = true
            const result = await useApi().getVesselChemicalRecommendationsV2({
                accountId: props.accountId,
                vesselId: props.vesselId,
                chemicalUse: props.chemicalUse
            })
            recommendedChemicals.value = result.chemicals
        },
        async () => {
            loading.value = false
        }
    )
}

onMounted(() => {
    getChemicalRecommendations()
})

const chooseChemical = (chemical: ChemicalV2) => {
    emit('didChooseChemical', props.chemicalUse, chemical)
}

watch(searchTerm, () => {
    if (searchTerm.value !== undefined && searchTerm.value !== null && searchTerm.value.length >= 3) {
        updateSearchResults()
    }
})

const updateSearchResults = async () => {
    const searchTermString = searchTerm.value
    if (searchTermString === undefined || searchTermString === null || searchTermString.length < 3) return

    handleApiError(
        async () => {
            loadingSearchResults.value = true

            const result = await useApi().searchForVesselChemicalV1({
                accountId: props.accountId,
                vesselId: props.vesselId,
                searchTerm: searchTermString,
                chemicalUse: props.chemicalUse
            })
            searchResults.value = result.chemicals
        },
        async () => {
            loadingSearchResults.value = false
        }
    )
}
</script>

<style lang="scss" scoped>
.chemicalImage {
    max-width: 60px;
    height: auto;
}
</style>
