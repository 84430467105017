import type { ReadingsV1, ReadingTypesV1, ReadingV1 } from '@geckoal/gecko-api-client'

export type ReadingConfig = { key: keyof ReadingsV1; title: string }
export type ReadingWithTitle = { title: string; readingType: ReadingTypesV1 } & ReadingV1
export type ExcludedReadingWithTitle = { title: string; date?: Date; exclusionReason: string; value: number }

export const allReadingsConfigs: ReadingConfig[] = [
    {
        key: 'ph',
        title: 'pH'
    },
    {
        key: 'orp',
        title: 'ORP'
    },
    {
        key: 'waterTemp',
        title: 'TEMP'
    },
    {
        key: 'cpuTemp',
        title: 'CPU'
    },
    {
        key: 'wifiRssi',
        title: 'RSSI'
    },
    {
        key: 'battery',
        title: 'BATT'
    },
    {
        key: 'freeChlorine',
        title: 'FC'
    },
    {
        key: 'totalChlorine',
        title: 'TC'
    },
    {
        key: 'bromine',
        title: 'BR'
    },
    {
        key: 'totalAlkalinity',
        title: 'TA'
    },
    {
        key: 'totalHardness',
        title: 'TH'
    },
    {
        key: 'cyanuricAcid',
        title: 'CYA'
    },
    {
        key: 'totalDissolvedSolids',
        title: 'TDS'
    },
    {
        key: 'salt',
        title: 'SALT'
    },
    {
        key: 'phosphates',
        title: 'PHOS'
    },
    {
        key: 'smartChlor',
        title: 'SC®'
    },
    {
        key: 'lsi',
        title: 'LSI'
    },
    {
        key: 'adjustedTotalAlkalinity',
        title: 'aTA'
    },
    {
        key: 'calciumHardness',
        title: 'CH'
    }
]

export const readingConfigsByKey = allReadingsConfigs.reduce((collector, config) => {
    collector[config.key] = config
    return collector
}, {} as Record<string, ReadingConfig>)

export function toReadingsWithTitles(readings?: ReadingsV1, source?: 'monitor' | 'report' | undefined): ReadingWithTitle[] {
    if (readings === undefined) return []

    const output: ReadingWithTitle[] = []
    for (const readingType of Object.keys(readings) as ReadingTypesV1[]) {
        const reading = readings[readingType]
        if (!reading) continue
        if (source !== undefined && reading.source !== source) continue

        const readingWithTitle = toReadingWithTitle(reading, readingType)
        if (readingWithTitle !== undefined) output.push(readingWithTitle)
    }
    return output
}

export function toReadingWithTitle(reading: ReadingV1, readingType: ReadingTypesV1): ReadingWithTitle | undefined {
    const readingConfig = readingConfigsByKey[readingType]
    if (readingConfig === undefined) return

    return {
        ...reading,
        readingType,
        title: readingConfig.title
    }
}
