<template>
    <v-row>
        <v-col>
            <v-text-field
                label="Date"
                v-model="dateOnly"
                required
                type="date"
                hide-details
                density="compact"
                class="mr-3"
                @change="verifyDate"
                :min-width="300"
                :max-width="300"
            />
        </v-col>
        <v-col>
            <v-text-field label="Time" v-model="timeOnly" required type="time" hide-details density="compact" @change="verifyDate" />
        </v-col>
        <v-col class="d-flex align-center">
            <v-chip v-for="(action, index) of actions" :key="index" color="success" @click="applyAdjustmentAction(action)" class="mr-3">{{
                titleForAction(action)
            }}</v-chip>
        </v-col>
    </v-row>
</template>

<script lang="ts">
export type AdjustmentAction = { duration: Duration; type: 'add' | 'subtract' }
</script>

<script lang="ts" setup>
import { add, format, formatDuration, isAfter, isValid, parse, sub } from 'date-fns'
import { computed, ref, watch, type PropType } from 'vue'

const DATE_FORMAT = 'yyyy-MM-dd'
const TIME_FORMAT = 'HH:mm:ss'

const props = defineProps({
    modelValue: {
        type: Object as PropType<Date | null>
    },
    allowFutureDate: {
        type: Boolean,
        required: false
    },
    actions: {
        type: Array as PropType<AdjustmentAction[]>
    }
})

const applyAdjustmentAction = (action: AdjustmentAction) => {
    if (updatedDate.value === undefined || updatedDate.value === null) return
    const newDate = action.type === 'add' ? add(updatedDate.value, action.duration) : sub(updatedDate.value, action.duration)

    const { dateValue, timeValue } = dateParts(newDate)

    dateOnly.value = dateValue
    timeOnly.value = timeValue
}

const titleForAction = (action: AdjustmentAction) => {
    if (action.type === 'add') return `+ ${formatDuration(action.duration)}`
    return `- ${formatDuration(action.duration)}`
}

const emit = defineEmits<{
    (event: 'update:modelValue', value: Date | undefined): void
    (event: 'validation-error', message: string): void
}>()

const syncVModel = (value: Date | undefined) => {
    emit('update:modelValue', value)
}

const dateOnly = ref<string | undefined>()
const timeOnly = ref<string | undefined>()

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue === undefined || props.modelValue === null) {
            dateOnly.value = undefined
            timeOnly.value = undefined
            return
        }

        const { dateValue, timeValue } = dateParts(props.modelValue)
        dateOnly.value = dateValue
        timeOnly.value = timeValue
    },
    { immediate: true }
)

function dateParts(date: Date): { dateValue: string; timeValue: string } {
    return {
        dateValue: format(date, DATE_FORMAT),
        timeValue: format(date, TIME_FORMAT)
    }
}

const updatedDate = computed(() => {
    // When you're typing in the field it sometimes sets the value to empty string, ignore these as transient values
    // returning null for transients values so we can skip the v-model sync below
    if (dateOnly.value !== undefined && dateOnly.value.length === 0) return null
    if (timeOnly.value !== undefined && timeOnly.value.length === 0) return null

    const date = parse(`${dateOnly.value} ${timeOnly.value}`, `${DATE_FORMAT} ${TIME_FORMAT}`, new Date())
    if (!isValid(date)) return undefined
    return date
})

watch(updatedDate, () => {
    if (updatedDate.value === null) return // transient update - see above
    syncVModel(updatedDate.value)
})

const verifyDate = () => {
    if (props.allowFutureDate) return

    const now = new Date()
    if (updatedDate.value && isAfter(updatedDate.value, now)) {
        const { dateValue, timeValue } = dateParts(now)
        dateOnly.value = dateValue
        timeOnly.value = timeValue
        emit('validation-error', 'You cannot choose a future date')
    }
}
</script>
