import { helpers } from '@vuelidate/validators'
import { isBefore, isValid, parse } from 'date-fns'

/**
 * The exported functions below are utils to use when you use two vuetify text fields
 * to collect a date and time. The vuetify validators will ensure the date is in the past
 */

/**
 * Returns a JS Date object from a date and time string from vuetify test fields.
 * Date Format: yyyy-MM-dd
 * Time Format: HH:mm (24 hour time)
 */
export const constructMergedDate = (state: VuetifyDateTimeState): Date | undefined => {
    if (state.date === undefined) return undefined
    const time = state.time !== undefined ? state.time : '00:00'
    return parse(`${state.date} ${time}`, 'yyyy-MM-dd HH:mm', new Date())
}

const mergedDateIsInThePast = (mergedDate: Date) => {
    return isValid(mergedDate) && isBefore(mergedDate, new Date())
}

export type VuetifyDateTimeState = {
    date: string | undefined
    time: string | undefined
}

export const isPastDate = helpers.withMessage('Date cannot be a future date', (value: string, state: VuetifyDateTimeState) => {
    if (!helpers.req(value)) return true
    const date = constructMergedDate({ date: state.date, time: '00:00' }) // only check the date without time for this validation
    if (date === undefined) return true
    return mergedDateIsInThePast(date)
})

export const isPastTime = helpers.withMessage('Date and time cannot be a future date', (value: string, state: VuetifyDateTimeState) => {
    if (!helpers.req(value)) return true
    const date = constructMergedDate(state)
    if (date === undefined) return true
    return mergedDateIsInThePast(date)
})
