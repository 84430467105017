<template>
    <small-reading-cell v-if="reading !== undefined" :reading="reading" />
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'
import type { ReadingsV1, ReadingTypesV1, VesselWaterReportV1ExcludedMeasurement } from '@geckoal/gecko-api-client'
import SmallReadingCell from '../SmallReadingCell.vue'
import { type ExcludedReadingWithTitle, type ReadingWithTitle } from '@/lib/readings'
import { readingInfoForReadingType, ReadingTypes } from '@geckoal/chem-engine'

const props = defineProps({
    readings: {
        type: Object as PropType<ReadingsV1>,
        required: true
    },
    excludedMeasurements: {
        type: Object as PropType<VesselWaterReportV1ExcludedMeasurement[]>,
        required: false
    },
    readingType: {
        type: String as PropType<ReadingTypesV1>,
        required: true
    },
    vesselTimezoneId: {
        type: String,
        required: false
    }
})

const excludedReading = computed<ExcludedReadingWithTitle | undefined>(() => {
    if (props.excludedMeasurements === undefined) return undefined

    const excludedReading = props.excludedMeasurements.find((m) => m.readingType === props.readingType)
    if (excludedReading === undefined) return undefined

    const info = readingInfoForReadingType(excludedReading.readingType as unknown as ReadingTypes)

    return {
        title: info?.abbreviation.toUpperCase() ?? excludedReading.readingType,
        exclusionReason: excludedReading.reason,
        value: excludedReading.value
    }
})

const readingWithTitle = computed<ReadingWithTitle | undefined>(() => {
    const reading = props.readings[props.readingType]
    if (reading === undefined) return undefined

    return {
        ...reading,
        readingType: props.readingType,
        title: reading.abbreviation.toUpperCase()
    }
})

const reading = computed(() => {
    return excludedReading.value ?? readingWithTitle.value
})
</script>

<style lang="scss" scoped>
.excluded {
    text-decoration: line-through;
}

.readingValue {
    font-weight: 600;
}
</style>
