<template>
    <v-menu v-model="menuVisible">
        <template v-slot:activator="{ props }">
            <v-btn color="light" v-bind="props" size="small"><v-icon start icon="mdi-clock-outline"></v-icon> {{ selectedTimezoneLabel }}</v-btn>
        </template>
        <v-list>
            <v-list-item v-for="label in ALL_TIMEZONE_LABELS" :key="label" :value="label" @click="selectTimeZone(label)">
                <v-list-item-title>{{ label }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { ALL_TIMEZONE_LABELS, ALL_ZONES_BY_LABEL, type Timezone } from '@geckoal/gecko-webapp-utils'
import { requireTimezone } from '@/lib/utils'

const emit = defineEmits<{
    (e: 'change', timezone: Timezone | undefined): void
}>()

const menuVisible = ref(false)
const selectedTimezoneLabel = ref<string | undefined>()

const currentTimeZone = requireTimezone()
selectedTimezoneLabel.value = currentTimeZone.label

const selectTimeZone = (label: string) => {
    selectedTimezoneLabel.value = label
    menuVisible.value = false
    const selectedTimezone = label ? ALL_ZONES_BY_LABEL[label] : undefined
    emit('change', selectedTimezone)
}
</script>
