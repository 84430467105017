<template>
    <span class="container">
        <v-alert v-if="errorMessage" title="Oops!" :text="errorMessage" type="error"></v-alert>
        <svg v-else id="barcode" ref="svg"></svg>
        <div v-if="!barcodeIsValid" class="noBarcode">{{ barcode }}</div>
        <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn size="small" icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
            </template>
            <v-list>
                <v-list-item title="Lookup UPC" :href="`https://www.barcodelookup.com/${barcode}`" target="_upcLookup">
                    <template v-slot:prepend>
                        <v-icon icon="mdi-barcode"></v-icon>
                    </template>
                </v-list-item>
                <v-list-item title="Copy Barcode" @click="copyBarcodeToClipboard">
                    <template v-slot:prepend>
                        <v-icon icon="mdi-content-copy"></v-icon>
                    </template>
                </v-list-item>
            </v-list>
        </v-menu>
    </span>
</template>

<script lang="ts" setup>
import { copyToClipboard } from '@/lib/utils'
import JsBarcode from 'jsbarcode'
import { computed, onMounted, watch } from 'vue'
import { ref } from 'vue'

const svg = ref<HTMLElement>()
const errorMessage = ref<string | undefined>()

const props = defineProps({
    barcode: {
        type: String,
        required: true
    },
    showActionButton: {
        type: Boolean,
        default: true
    }
})

const cleanBarcode = computed(() => {
    return props.barcode.trim()
})

watch(cleanBarcode, () => {
    render()
})

onMounted(() => {
    render()
})

const barcodeIsValid = ref(false)

const render = () => {
    const barcode = cleanBarcode.value

    let format = 'CODE39'
    if (barcode.length === 12) {
        format = 'UPC'
    } else if (barcode.length === 13) {
        format = 'EAN13'
    }

    try {
        const settings: JsBarcode.Options = {
            format,
            width: 1.5,
            height: 80,
            valid: (valid: boolean) => {
                console.log('IS VALID', valid)
                barcodeIsValid.value = valid
                return true
            }
        }
        // console.log('RENDER BARCODE', barcode, settings)
        JsBarcode(svg.value, barcode, settings)
    } catch (error) {
        if (error instanceof Error) {
            errorMessage.value = error.message
        } else {
            errorMessage.value = String(error)
        }
    }
}

const copyBarcodeToClipboard = () => {
    copyToClipboard(String(props.barcode))
    // showCopiedBarcodeSnackbar.value = true
}
</script>

<style scoped lang="scss">
.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 6px;
    padding: 6px;
    position: relative;

    .noBarcode {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-family: 'Courier New', Courier, monospace;
    }
}
</style>
