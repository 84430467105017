<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>{{ vesselName }} -> {{ protocolInfo?.protocolName }}</h1>
            </v-col>
        </v-row>

        <v-row v-if="protocolInfo?.configuredActionTypes" class="mb-5">
            <v-col>
                <h3 class="mb-3">Action Types</h3>
                <v-table>
                    <thead>
                        <tr>
                            <th>Action Type</th>
                            <th></th>
                            <th>Chemical Use</th>
                            <th>Suppression</th>
                            <th>Visible to User?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="actionType of protocolInfo?.configuredActionTypes" :key="actionType.type">
                            <th>{{ actionType.title }}</th>
                            <td>
                                <v-chip v-if="actionType.isChemicalAction" color="primary" variant="flat" class="mr-2">Chemical Action</v-chip>
                                <v-chip v-if="actionType.isTimedAction" color="success" variant="flat" class="mr-2">Timed Action</v-chip>
                                <v-chip v-if="actionType.isSystemAction" color="warning" variant="flat" class="mr-2">System Action</v-chip>
                            </td>
                            <td>
                                {{ actionType.neededChemicalUse }}
                            </td>
                            <td>
                                {{ actionType.suppressForDuration }}
                            </td>
                            <td>
                                <span v-if="!actionType.showInAppActionStatuses">Hidden in App Action Status</span>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>

        <v-row v-if="protocolInfo?.readingConfigs !== undefined && protocolInfo?.readingConfigs.length > 0" class="mb-5">
            <v-col>
                <h3 class="mb-3">Reading Configs</h3>
                <v-table>
                    <thead>
                        <tr>
                            <th>Reading Type</th>
                            <th>Levels</th>
                            <th>Levels Overridden</th>
                            <th>Useable Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="readingConfig of protocolInfo?.readingConfigs" :key="readingConfig.readingType">
                            <th>{{ readingConfig.readingTypeTitle }}</th>
                            <td>
                                <v-chip
                                    v-for="level of readingConfig.levels"
                                    :key="level.color"
                                    class="mr-2"
                                    variant="flat"
                                    :color="colorForColor(level.color)"
                                    >{{ level.thresholdDescription }}</v-chip
                                >
                            </td>
                            <td>
                                <v-chip v-if="readingConfig.levelsAreOverridden" variant="flat" color="red-darken-2">OVERRIDDEN</v-chip>
                            </td>
                            <td>
                                <p v-for="description of readingConfig.useableDurationDescriptions" :key="description">{{ description }}</p>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>

        <v-row v-if="protocolInfo?.recommendedTestStrip !== undefined" class="mb-5">
            <v-col>
                <h3 class="mb-3">Recommended Test Strip</h3>
                <v-table>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{{ protocolInfo?.recommendedTestStrip.testStrip.name }}</td>
                        </tr>
                        <tr>
                            <th>SKU</th>
                            <td>{{ protocolInfo?.recommendedTestStrip.testStrip.sku }}</td>
                        </tr>
                        <tr v-if="protocolInfo?.recommendedTestStrip.testStrip.barcode">
                            <th>Barcode</th>
                            <td>{{ protocolInfo?.recommendedTestStrip.testStrip.barcode }}</td>
                        </tr>
                        <tr>
                            <th>User Can Change</th>
                            <td>{{ protocolInfo?.recommendedTestStrip.canBeChanged ? 'Yes' : 'No' }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>

        <v-row v-if="protocolInfo?.protocolData !== undefined" class="mb-5">
            <v-col>
                <h3 class="mb-3">Protocol Data</h3>
                {{ protocolInfo?.protocolName }}
                <pre>{{ JSON.stringify(protocolInfo.protocolData, null, '\t') }}</pre>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useVesselStore } from '@/stores/vessel'
import { useApi } from '@/api'
import { computed } from 'vue'
import { VesselProtocolInfoV1LevelColorEnum, type VesselProtocolInfoV1 } from '@geckoal/gecko-api-client'

const breadCrumbsStore = useBreadCrumbsStore()
const vesselStore = useVesselStore()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})

const protocolInfo = ref<VesselProtocolInfoV1 | undefined>()

const vesselName = computed(() => {
    return vesselStore.vessel?.name
})

onMounted(async () => {
    await vesselStore.loadVessel(props.accountId, props.vesselId)
    getProtocolInfo()
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`,
                to: {
                    name: 'AccountDetails',
                    params: {
                        accountId: props.accountId
                    }
                }
            },
            {
                text: vesselStore.vessel?.name,
                to: {
                    name: 'VesselDetails',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: 'Protocol Info'
            }
        ]
    })
})

async function getProtocolInfo() {
    protocolInfo.value = await useApi().getVesselProtocolInfoV1({ accountId: props.accountId, vesselId: props.vesselId })
}

const colorForColor = (color: VesselProtocolInfoV1LevelColorEnum): string => {
    if (color === VesselProtocolInfoV1LevelColorEnum.Blue) return 'primary'
    if (color === VesselProtocolInfoV1LevelColorEnum.Red) return 'red-darken-2'
    if (color === VesselProtocolInfoV1LevelColorEnum.Orange) return 'warning'
    return 'default'
}
</script>

<style lang="scss" scoped></style>
