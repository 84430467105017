import { createAuth0, type GetTokenSilentlyOptions } from '@auth0/auth0-vue'
import { GG_AUTH0_ROLES_KEY } from '@geckoal/gecko-webapp-utils'

export const CALLBACK_URL = `${window.location.origin}/callback`
export const LOGIN_URL = `${window.location.origin}/login`

export const auth0 = createAuth0(
    {
        domain: import.meta.env.VITE_AUTH0_DOMAIN,
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
        authorizationParams: {
            redirect_uri: CALLBACK_URL,
            audience: 'https://api.geckowatermonitor.com'
        }
    },
    {
        errorPath: '/login'
    }
)

export async function getAccessTokenSilentlyOutsideComponent(options?: GetTokenSilentlyOptions): Promise<string> {
    return auth0.getAccessTokenSilently(options)
}

export function isAuthenticated(): boolean {
    return auth0.isAuthenticated.value === true
}

export function hasValidRole(): boolean {
    const token = auth0.idTokenClaims.value
    if (!token) return false

    const roles = token[GG_AUTH0_ROLES_KEY]
    if (!roles || !Array.isArray(roles)) return false

    if (roles.indexOf('admin') === -1) return false

    return true
}

export function logout() {
    auth0.logout({
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
        logoutParams: {
            returnTo: LOGIN_URL
        }
    })
}
