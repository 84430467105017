import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { useApi } from './api'
import { auth0, hasValidRole } from './lib/auth'
import { VueMasonryPlugin } from 'vue-masonry'

loadFonts()
;(async () => {
    const app = createApp(App)

    const pinia = createPinia()
    pinia.use(() => {
        return {
            useApi
        }
    })

    app.use(vuetify)
    app.use(pinia)
    app.use(router)
    app.use(auth0)
    app.use(VueMasonryPlugin)

    // app.use(authPlugin)

    // await auth.initialize()

    // await useGeneralStore().updateToken()

    await router.isReady()

    if (auth0.isAuthenticated) {
        if (hasValidRole()) await useApi({ rebuild: true })
    } else {
        router.replace({ name: 'login' })
    }

    app.mount('#app')
})()
