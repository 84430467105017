<template>
    <v-card min-height="400" v-if="monitorId">
        <v-row>
            <v-col>
                <v-toolbar density="compact" color="#ccc">
                    <v-btn-toggle v-model="chartDuration" divided mandatory color="deep-purple-accent-3" rounded="0">
                        <v-btn :value="GetVesselChartV3ChartDurationEnum._7d">Last Week</v-btn>
                        <v-btn :value="GetVesselChartV3ChartDurationEnum._30d">Last Month</v-btn>
                        <v-btn :value="GetVesselChartV3ChartDurationEnum._90d">Last 3 Months</v-btn>
                        <v-btn :value="GetVesselChartV3ChartDurationEnum._365d">Last Year</v-btn>
                    </v-btn-toggle>

                    <v-btn icon size="small" color="light" style="margin-left: 10px" @click="getMonitorCharts"><v-icon>mdi-reload</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <time-zone-select @change="updateTimeZone" style="margin-left: 10px" />
                    <v-btn icon size="small" color="light" style="margin-left: 10px" @click="downloadCSV">
                        <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                    <v-menu :close-on-content-click="false" location="start top">
                        <template v-slot:activator="{ props }">
                            <v-btn icon v-bind="props" size="small" color="light" style="margin-left: 10px">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-card min-width="300">
                            <v-list>
                                <v-list-item>
                                    <v-switch v-model="largeCharts" color="purple" label="Large Charts" hide-details></v-switch>
                                </v-list-item>
                                <v-divider />
                                <v-list-item v-for="key of Object.keys(filteredVisibleCharts)" :key="key" density="compact">
                                    <v-switch v-model="visibleCharts[key as ChartsKeys]" color="purple" :label="key" hide-details></v-switch>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-overlay persistent v-model="loading" contained class="align-center justify-center">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <charts
            v-if="startDate && endDate"
            :start-date="startDate"
            :end-date="endDate"
            :monitor-averaged="monitorAveraged"
            :water-report="waterReport"
            :visible-charts="visibleCharts"
            :loading="loading"
            :timezone="timezone"
            :large="largeCharts"
            :ok-ranges="okChartRanges"
            :event-horizon="eventHorizon"
        />
    </v-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import TimeZoneSelect from './TimeZoneSelect.vue'
import { Charts, type Timezone, type ChartDataStreams, type VisibleCharts, downloadChartDataStreamsToCSVFile, ChartsKeys } from '@geckoal/gecko-webapp-utils'
import { CrystalAPIMonitorChartFetcher } from '../lib/CrystalAPIMonitorChartFetcher'
import {
    GetVesselChartV3ChartDurationEnum,
    GetVesselChartV3ChartTypeEnum,
    GetVesselWaterReportChartsV1ReadingTypesEnum,
    type ChartRangesV1
} from '@geckoal/gecko-api-client'
import { handleApiError, requireTimezone } from '@/lib/utils'

const monitorChartFetcher = new CrystalAPIMonitorChartFetcher()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    monitorId: {
        type: String,
        required: true
    },
    eventHorizon: {
        type: Date,
        required: false
    },
    vesselTimezoneId: {
        type: String
    }
})

const timezone = ref<Timezone>(requireTimezone())

const startDate = ref<Date | undefined>()
const endDate = ref<Date | undefined>()

const chartDuration = ref<GetVesselChartV3ChartDurationEnum>(GetVesselChartV3ChartDurationEnum._7d)

const monitorAveraged = ref<ChartDataStreams | undefined>()
const waterReport = ref<ChartDataStreams | undefined>()

const loadingWaterReportCharts = ref(false)
const loadingMonitorCharts = ref(false)

const largeCharts = ref(true)
const okChartRanges = ref<ChartRangesV1 | undefined>()

const visibleCharts = ref<VisibleCharts>({
    ph: true,
    orpMv: true,
    waterTempC: true,
    cpuTempC: true,
    batteryMv: true,
    wifiRssi: true,
    flags: true,
    timing: true
})

onMounted(() => {
    getMonitorCharts()
})

watch(chartDuration, () => {
    getMonitorCharts()
})

const downloadCSV = () => {
    downloadChartDataStreamsToCSVFile(String(props.vesselId), monitorAveraged.value, undefined, waterReport.value, startDate.value, endDate.value)
}

const filteredVisibleCharts = computed<VisibleCharts>(() => {
    const output: VisibleCharts = {}
    for (const key of Object.keys(visibleCharts.value)) {
        const averagedDates = monitorAveraged.value?.dates
        const waterReportDates = waterReport.value?.dates

        const hasAveragedData = averagedDates && averagedDates.length > 0
        const hasWaterReportData = waterReportDates && waterReportDates.length > 0

        output[key as keyof VisibleCharts] = hasAveragedData || hasWaterReportData
    }
    return output
})

const loading = computed(() => {
    return loadingMonitorCharts.value === true || loadingWaterReportCharts.value === true
})

const getMonitorCharts = async () => {
    if (props.monitorId === undefined) return
    // console.log("LOADING CHARTS", props.monitorId, props.vesselId)

    await handleApiError(
        async () => {
            loadingMonitorCharts.value = true

            const {
                charts,
                startDate: _startDate,
                endDate: _endDate,
                ranges
            } = await monitorChartFetcher.getVesselChart({
                accountId: props.accountId,
                vesselId: props.vesselId,
                chartDuration: chartDuration.value,
                chartType: GetVesselChartV3ChartTypeEnum.Admin
            })

            startDate.value = _startDate
            endDate.value = _endDate

            monitorAveraged.value = charts
            okChartRanges.value = ranges

            getWaterReportsCharts() // Needs startDate and endDate
        },
        async () => {
            loadingMonitorCharts.value = false
        }
    )
}

const getWaterReportsCharts = async () => {
    if (!startDate.value || !endDate.value) return
    await handleApiError(
        async () => {
            loadingWaterReportCharts.value = true
            const result = await monitorChartFetcher.getWaterReportChart({
                accountId: props.accountId,
                vesselId: props.vesselId,
                startDate: startDate.value,
                endDate: endDate.value,
                readingTypes: [
                    GetVesselWaterReportChartsV1ReadingTypesEnum.Ph,
                    GetVesselWaterReportChartsV1ReadingTypesEnum.FcPpm,
                    GetVesselWaterReportChartsV1ReadingTypesEnum.WaterTempC,
                    GetVesselWaterReportChartsV1ReadingTypesEnum.CyaPpm,
                    GetVesselWaterReportChartsV1ReadingTypesEnum.TaPpm
                ]
            })
            waterReport.value = result
        },
        async () => {
            loadingWaterReportCharts.value = false
        }
    )
}

const updateTimeZone = (newTimezone?: Timezone) => {
    if (!newTimezone) throw new Error(`No timezone`)
    timezone.value = newTimezone
}
</script>

<style lang="scss" scoped>
.chartsHeader {
    background-color: #ccc;
    padding: 6px;
}
</style>
