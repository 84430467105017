<template>
    <info-card title="Messages" :min-height="120" :loading="loading" :is-empty="messages === undefined || messages.length === 0" empty-title="No Messages">
        <template #empty>
            <v-sheet rounded style="min-height: 120px" class="empty">
                <h3>No Messages</h3>
                <v-btn
                    density="compact"
                    icon="mdi-plus"
                    color="primary"
                    title="Send Message"
                    @click="showSendMessageDialog = true"
                    class="noMessagesAddButton"
                ></v-btn>
            </v-sheet>
        </template>
        <v-table class="messages" v-if="messages && messages.length > 0">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th class="text-center">Status</th>
                    <th class="text-right">
                        <!-- <v-btn density="compact" icon="mdi-refresh" color="danger" title="Refresh" @click="loadMessages" variant="plain" style="margin-right: 6px;"></v-btn> -->
                        <v-btn density="compact" icon="mdi-plus" color="primary" title="Send Message" @click="showSendMessageDialog = true"></v-btn>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="message of messages"
                    :key="message.messageId"
                    :class="{
                        userDeleted: message.isUserDeleted,
                        unread: !message.isRead,
                        selectedForDelete: messageToDelete?.messageId === message.messageId
                    }"
                    @click="showMessageContent(message)"
                    class="messageRow"
                >
                    <th>{{ formatDate(message.sentAt) }}</th>
                    <td>
                        {{ message.title }}
                    </td>
                    <td class="text-center">
                        <v-chip v-if="message.isUserDeleted" color="grey-darken-1">user deleted</v-chip>
                        <v-chip v-else-if="message.isRead" color="light-blue-darken-4">read</v-chip>
                        <v-chip v-else color="red-darken-2">unread</v-chip>
                    </td>
                    <td class="text-right">
                        <v-btn
                            density="compact"
                            icon="mdi-delete"
                            color="danger"
                            title="Delete"
                            class="deleteButton"
                            @click.stop="showConfirmDeleteMessage(message)"
                            variant="plain"
                        ></v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
    </info-card>

    <v-dialog v-model="showConfirmDeleteDialog" max-width="800">
        <v-card>
            <v-card-title>Are you sure you want to delete this message?</v-card-title>
            <v-card-actions>
                <v-btn color="red-darken-1" :loading="loading" @click="deleteMessage">YES, DELETE IT</v-btn>
                <v-btn color="gray-darken-1" @click=";(showConfirmDeleteDialog = false), (messageToDelete = undefined)">NO, CANCEL</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showMessageContentDialog" max-width="800">
        <v-card>
            <v-card-text>
                <v-row v-if="messageToShow">
                    <v-col cols="9"
                        ><h2>{{ messageToShow?.title }}</h2></v-col
                    >
                    <v-col cols="3" class="text-right">{{ formatDate(messageToShow.sentAt) }}</v-col>
                </v-row>
                <div v-html="messageToShowContentHTML" class="messagePreview markdownContent" />
                <div v-if="messageToShow?.reads" class="reads">
                    <h4>MESSAGE READS</h4>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>User</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="read of messageToShow.reads.slice(0, 6)" :key="read.date.toISOString()">
                                <td>
                                    <strong>{{ formatDate(read.date) }}</strong>
                                </td>
                                <td>{{ read.user.name }} - {{ read.user.email }}</td>
                            </tr>
                            <tr v-if="messageToShow.reads.length > 6">
                                <td colspan="2" class="text-center">not showing {{ messageToShow.reads.length - 6 }} other reads</td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click=";(showMessageContentDialog = false), (messageToShow = undefined)">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showSendMessageDialog" max-width="1000">
        <v-card>
            <v-card-title>Send Message</v-card-title>
            <v-tabs v-model="addMessageTab" align-tabs="center" color="primary">
                <v-tab value="custom">Custom Message</v-tab>
                <v-tab value="canned">Canned Message</v-tab>
            </v-tabs>
            <v-card-text style="margin-top: 10px">
                <v-window v-model="addMessageTab">
                    <v-window-item value="custom">
                        <v-row>
                            <v-col lg="6">
                                <v-text-field
                                    v-model="customMessageV$.title.$model"
                                    label="Title"
                                    required
                                    :error-messages="vuelidateErrors(customMessageV$.title)"
                                    @input="customMessageV$.title.$touch"
                                    @blur="customMessageV$.title.$touch"
                                    auto-grow
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="6">
                                <v-switch
                                    class="ml-3"
                                    hide-details
                                    density="compact"
                                    label="Send Push Notification"
                                    v-model="customMessageState.sendPushNotification"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="customMessageV$.contentMarkdown.$model"
                                    label="Message"
                                    required
                                    :error-messages="vuelidateErrors(customMessageV$.contentMarkdown)"
                                    @input="customMessageV$.contentMarkdown.$touch"
                                    @blur="customMessageV$.contentMarkdown.$touch"
                                />
                            </v-col>
                            <v-col class="messagePreview">
                                <h4 class="previewTitle">Message Preview</h4>
                                <div v-html="markdownHTML" class="markdownContent" />
                            </v-col>
                        </v-row>
                    </v-window-item>

                    <v-window-item value="canned">
                        <v-row>
                            <v-col lg="6">
                                <v-select
                                    v-model="cannedMessageV$.cannedMessageType.$model"
                                    :items="cannedMessageTypes"
                                    label="Canned Message"
                                    :error-messages="vuelidateErrors(cannedMessageV$.cannedMessageType)"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="6">
                                <v-switch
                                    class="ml-3"
                                    hide-details
                                    density="compact"
                                    label="Send Push Notification"
                                    v-model="cannedMessageState.sendPushNotification"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-window-item>
                </v-window>
            </v-card-text>
            <v-card-actions>
                <v-btn :loading="sendingMessage" @click="sendMessage" color="primary">Send</v-btn>
                <v-btn color="gray-darken-1" @click="showSendMessageDialog = false">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { reactive, ref, watch } from 'vue'
import { formatDate, handleApiError, vuelidateErrors } from '@/lib/utils'
import { useApi } from '@/api'
import { PutAccountMessageRequestV2CannedMessageEnum, type AccountMessageV1 } from '@geckoal/gecko-api-client'
import { onMounted } from 'vue'
import useVuelidate from '@vuelidate/core'
import { maxLength, required } from '@vuelidate/validators'
import { Marked } from 'marked'
import type { Tokens } from 'marked'
import InfoCard from '../InfoCard.vue'
import { useGeneralStore } from '@/stores/general'

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    }
})

const emit = defineEmits(['messagesUpdated'])

const marked = new Marked()

// Override link rendering to insert a target="_blank"
marked.use({
    extensions: [
        {
            name: 'link',
            renderer(token: Tokens.Generic): string | false | undefined {
                if (token.type === 'link') {
                    const link = token as Tokens.Link
                    return `<a href="${link.href}" title="${link.title}" target="_blank">${link.text}</a>`
                }
                return undefined
            }
        }
    ]
})

const loading = ref(false)
const messages = ref<AccountMessageV1[] | undefined>()
const messageToDelete = ref<AccountMessageV1 | undefined>()
const showConfirmDeleteDialog = ref(false)

const showSendMessageDialog = ref(false)
const sendingMessage = ref(false)

const showMessageContentDialog = ref(false)
const messageToShow = ref<AccountMessageV1 | undefined>()

const addMessageTab = ref<'custom' | 'canned'>('custom')
const cannedMessageTypes = ref([
    {
        title: 'Welcome Message',
        value: PutAccountMessageRequestV2CannedMessageEnum.Welcome
    },
    {
        title: 'Add a Payment Method',
        value: PutAccountMessageRequestV2CannedMessageEnum.AddPaymentMethod
    }
])

const loadMessages = async () => {
    handleApiError(
        async () => {
            loading.value = true
            const result = await useApi().getAccountMessagesV1({
                accountId: props.accountId,
                returnUserDeletedMessages: true,
                returnReads: true
            })
            messages.value = result.messages
            setTimeout(() => emit('messagesUpdated'), 100)
        },
        async () => {
            loading.value = false
        }
    )
}

onMounted(() => {
    loadMessages()
})

watch(
    () => props.accountId,
    () => {
        loadMessages()
    }
)

const markdownHTML = ref<string | undefined>()

const showConfirmDeleteMessage = (message: AccountMessageV1) => {
    messageToDelete.value = message
    showConfirmDeleteDialog.value = true
}

const deleteMessage = () => {
    const message = messageToDelete.value
    if (message === undefined) return

    handleApiError(
        async () => {
            loading.value = true
            await useApi().delAccountMessageV1({
                accountId: props.accountId,
                messageId: message.messageId,
                isAdminDelete: true
            })
            loadMessages()
        },
        async () => {
            loading.value = false
            messageToDelete.value = undefined
            showConfirmDeleteDialog.value = false
        }
    )
}

const sendMessage = async () => {
    if (addMessageTab.value === 'custom') {
        await sendCustomMessage()
    } else if (addMessageTab.value === 'canned') {
        await sendCannedMessage()
    }
}

type CannedMessageToSendState = {
    cannedMessageType: PutAccountMessageRequestV2CannedMessageEnum.Welcome | PutAccountMessageRequestV2CannedMessageEnum.AddPaymentMethod | undefined
    sendPushNotification: boolean
}

const cannedMessageState = reactive<CannedMessageToSendState>({
    cannedMessageType: undefined,
    sendPushNotification: false
})

const cannedMessageRules = {
    cannedMessageType: { required }
}

const cannedMessageV$ = useVuelidate(cannedMessageRules, cannedMessageState)

const sendCannedMessage = async () => {
    const isFormCorrect = await cannedMessageV$.value.$validate()
    if (!isFormCorrect) return

    sendingMessage.value = true

    handleApiError(
        async () => {
            const response = await useApi().putAccountMessageV2({
                accountId: props.accountId,
                putAccountMessageRequestV2: {
                    cannedMessage: cannedMessageState.cannedMessageType,
                    sendPushNotification: cannedMessageState.sendPushNotification
                }
            })

            console.log('Saved message id: ', response.messageId)

            if (cannedMessageState.sendPushNotification === true && response.mobileDeviceCount === 0) {
                const generalStore = useGeneralStore()
                generalStore.$patch({
                    globalErrorMessage: `This user doesn't have any devices registered for push notifications.`
                })
            }

            showSendMessageDialog.value = false
            cannedMessageState.cannedMessageType = undefined
            cannedMessageState.sendPushNotification = false
            await loadMessages()
        },
        async () => {
            sendingMessage.value = false
        }
    )
}

type CustomMessageToSendState = {
    title: string | undefined
    contentMarkdown: string | undefined
    sendPushNotification: boolean
}

const customMessageState = reactive<CustomMessageToSendState>({
    title: undefined,
    contentMarkdown: undefined,
    sendPushNotification: false
})

const customMessageRules = {
    title: { required, maxValue: maxLength(250) },
    contentMarkdown: { required, maxValue: maxLength(2048) }
}

const customMessageV$ = useVuelidate(customMessageRules, customMessageState)

const sendCustomMessage = async () => {
    const isFormCorrect = await customMessageV$.value.$validate()
    if (!isFormCorrect) return

    sendingMessage.value = true

    handleApiError(
        async () => {
            if (customMessageState.title === undefined || customMessageState.contentMarkdown === undefined) return // doesn't happen

            const response = await useApi().putAccountMessageV2({
                accountId: props.accountId,
                putAccountMessageRequestV2: {
                    customMessage: {
                        title: customMessageState.title,
                        contentMarkdown: customMessageState.contentMarkdown
                    },
                    sendPushNotification: customMessageState.sendPushNotification
                }
            })

            console.log('Saved message id: ', response.messageId)

            if (customMessageState.sendPushNotification === true && response.mobileDeviceCount === 0) {
                const generalStore = useGeneralStore()
                generalStore.$patch({
                    globalErrorMessage: `This user doesn't have any devices registered for push notifications.`
                })
            }

            showSendMessageDialog.value = false
            customMessageState.title = undefined
            customMessageState.contentMarkdown = undefined
            customMessageState.sendPushNotification = false
            await loadMessages()
        },
        async () => {
            sendingMessage.value = false
        }
    )
}

/**
 * NOTE: we're not preventing any XSS attacks here. All content is submitted by admins - not users - so less of a risk. If we change our mind look into DOMPurify.
 */
watch(
    () => customMessageState.contentMarkdown,
    async (value) => {
        markdownHTML.value = value === undefined ? undefined : await marked.parse(value, { breaks: true, gfm: true })
    }
)

const showMessageContent = (message: AccountMessageV1) => {
    messageToShow.value = message
    showMessageContentDialog.value = true
}

const messageToShowContentHTML = ref<string | undefined>()
watch(
    () => messageToShow.value?.contentMarkdown,
    async (value) => {
        messageToShowContentHTML.value = value === undefined ? undefined : await marked.parse(value)
    }
)
</script>

<style lang="scss" scoped>
$deleteButtonColor: #8c8c8c;
$deleteColor: #ba3838;

.userDeleted {
    color: #8c8c8c;

    .v-btn {
        color: $deleteButtonColor;
    }
}

.unread {
    color: $deleteColor;

    .v-btn {
        color: $deleteButtonColor;
    }
}

.deleteButton {
    color: $deleteButtonColor;

    &:hover {
        color: $deleteColor;
    }
}

.selectedForDelete {
    text-decoration: line-through;
    color: white;
    background-color: lighten($deleteColor, 10%);
}

.previewTitle {
    color: #444;
    font-size: 0.7em;
    font-weight: 300;
}

.messagePreview {
    background-color: #f6f6f6;
    margin: 12px;
    border-radius: 4px;
    padding: 12px;

    h4 {
        margin-bottom: 3px;
    }
}

.loading {
    padding: 40px;
    text-align: center;
}

.messageRow {
    cursor: pointer;
    &:hover {
        background-color: rgb(var(--v-theme-hover-background));
    }
}

.empty {
    position: relative;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        font-weight: 200;
        font-size: 1em;
    }

    .noMessagesAddButton {
        position: absolute;
        top: 6px;
        right: 6px;
    }
}

.reads {
    border-top: 1px solid silver;
    margin-top: 20px;
    padding-top: 20px;
}
</style>

<style lang="scss">
.markdownContent {
    blockquote {
        margin-left: 20px;
    }

    ul {
        margin-left: 20px;
    }

    ol {
        margin-left: 20px;
    }

    p {
        margin: 1em 0;
    }
}
</style>
