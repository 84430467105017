import { CUSTOM_ACTIONS_VERSION } from '@/lib/utils'
import type { AccountVesselV1 } from '@geckoal/gecko-api-client'
import { defineStore } from 'pinia'

export type VesselState = {
    vessel?: AccountVesselV1
}

export const useVesselStore = defineStore({
    id: 'vessel',
    state: () => {
        return {
            vessel: undefined
        } as VesselState
    },
    actions: {
        async loadVessel(accountId: number, vesselId: number, forceLoad = false, forceGeoUpdate = false) {
            if (!forceLoad && this.vessel?.vesselId === vesselId) return // caching, TODO: may want to check a load time here
            try {
                this.vessel = undefined
                this.vessel = await (
                    await this.useApi()
                ).getAccountVesselDetailsV3({
                    accountId,
                    vesselId,
                    includeInvalidReadings: 'true',
                    forceGeoUpdate,
                    customActionsVersion: CUSTOM_ACTIONS_VERSION
                })
            } catch (error) {
                this.vessel = undefined
                throw error
            }
        }
    }
})
