<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel">
        <v-card>
            <v-card-text>
                <v-btn @click="updateStatus(PutVesselStatusV1RequestScenarioEnum.ClearAll)" class="mr-2">CLEAR READINGS</v-btn>
                <v-btn @click="updateStatus(PutVesselStatusV1RequestScenarioEnum.MonitorOk)" class="mr-2">MONITOR OK</v-btn>
                <v-btn @click="updateStatus(PutVesselStatusV1RequestScenarioEnum.MonitorNeedsAttention)">MONITOR NEEDS ATTENTION</v-btn>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" block @click="hide">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { PutVesselStatusV1RequestScenarioEnum } from '@geckoal/gecko-api-client'

const props = defineProps({
    modelValue: {
        type: Boolean
    },
    vesselId: {
        type: Number,
        required: true
    }
})

const emit = defineEmits(['update:modelValue', 'statusUpdated'])

const updateStatus = async (scenario: PutVesselStatusV1RequestScenarioEnum) => {
    try {
        await useApi().putVesselStatusV1({
            vesselId: props.vesselId,
            putVesselStatusV1Request: {
                scenario
            }
        })
        console.log('Updated vessel status')
        emit('statusUpdated')
        hide()
    } catch (error) {
        console.error('Error updating vessel status', error)
    }
}

const hide = () => {
    emit('update:modelValue', false)
}

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped></style>
