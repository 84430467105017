<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-btn-group :color="color" :density="density">
                <v-btn
                    :size="size"
                    :variant="variant"
                    :to="{ name: 'SensorDetails', params: { serialNumber: sensor.serialNumber } }"
                    title="View Sensor Details"
                    :target="target"
                    :append-icon="showOpenInIcon && target !== undefined ? 'mdi-open-in-new' : undefined"
                    >Sensor #{{ sensor.serialNumber }} &#8226; {{ sensor.type }}</v-btn
                >
                <v-btn v-if="monitorId" size="small" icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </v-btn-group>
        </template>

        <v-list>
            <v-list-item @click="showDetachSensorModal = true" title="Detach From Monitor">
                <template v-slot:prepend>
                    <v-icon color="red-darken-1" icon="mdi-minus-circle"></v-icon>
                </template>
            </v-list-item>
        </v-list>
    </v-menu>

    <detach-sensor-modal
        v-if="monitorId"
        v-model="showDetachSensorModal"
        :monitor-id="monitorId"
        :serial-number="sensor.serialNumber"
        @sensor-detached="sensorDetached"
    />
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { SensorTypesV1 } from '@geckoal/gecko-api-client'
import { computed, ref } from 'vue'
import DetachSensorModal from '../monitors/DetachSensorModal.vue'

export type SensorSummary = {
    serialNumber: string
    type: SensorTypesV1
}

const props = defineProps({
    sensor: {
        type: Object as PropType<SensorSummary>,
        required: true
    },
    monitorId: {
        type: String
    },
    size: {
        type: String as PropType<'x-small' | 'small' | 'large' | 'x-large'>,
        default: 'large'
    },
    density: {
        type: String as PropType<'compact' | 'comfortable'>
    },
    variant: {
        type: String as PropType<'elevated' | 'flat' | 'tonal' | 'outlined' | 'text' | 'plain'>,
        default: 'flat'
    },
    color: {
        type: String,
        default: 'primary'
    },
    target: {
        type: String
    },
    showOpenInIcon: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits<{
    (event: 'sensorDetached'): void
}>()

const showDetachSensorModal = ref(false)

const color = computed(() => {
    if (props.sensor.type === 'gold') return 'amber-lighten-2'
    return 'grey'
})

const sensorDetached = () => {
    emit('sensorDetached')
}
</script>

<style scoped lang="scss"></style>
