<template>
    <v-card color="#1F7087" rounded theme="dark" v-if="vessel?.location" class="vesselCard clickable" min-width="220px" max-width="300px" height="200px">
        <v-img v-if="vessel.map?.staticImageUrl" :src="vessel.map.staticImageUrl" cover height="110px" />
        <v-card-text style="position: relative">
            <div>{{ vessel.location.line1 }}</div>
            <div v-if="vessel.location.line2">{{ vessel.location.line2 }}</div>
            <div>
                {{ vessel.location.locality }} {{ vessel.location.administrativeAreaLevel1 }}, {{ vessel.location.postalCode
                }}<span v-if="vessel.location.country">, {{ vessel.location.country }}</span>
            </div>
            <small v-if="vessel.location?.timeZone?.timeZoneId">
                {{ vessel.location.timeZone.timeZoneId }}
            </small>

            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn
                        icon="mdi-dots-vertical-circle"
                        class="vesselCardAction"
                        title="Location Actions"
                        variant="text"
                        density="compact"
                        size="x-large"
                        v-bind="props"
                    ></v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item @click="editLocation" button title="Edit Location">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-pencil" class="mr-2"></v-icon>
                            </template>
                        </v-list-item>
                        <v-list-item @click="updateLocationGeo" button title="Update Geo Location and Timezone">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-compass" class="mr-2"></v-icon>
                            </template>
                        </v-list-item>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-card-text>
    </v-card>
</template>

<script lang="ts" setup>
import { useVesselStore } from '@/stores/vessel'
import { computed } from 'vue'

const vesselStore = useVesselStore()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})

const emits = defineEmits(['editLocation'])

const vessel = computed(() => {
    return vesselStore.vessel
})

const updateLocationGeo = async () => {
    await vesselStore.loadVessel(props.accountId, props.vesselId, true, true)
}

const editLocation = () => {
    emits('editLocation')
}
</script>

<style lang="scss" scoped></style>
