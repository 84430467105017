<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel" max-width="1000">
        <v-card :min-width="800">
            <v-card-title>
                <h3>Dose {{ chemical?.name }}</h3>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col class="productInfo">
                            <h3>
                                {{ chemical ? formatIngredientName(chemical) : undefined }}
                                <span v-if="chemical?.chemicalPercentage !== undefined">{{ chemical.chemicalPercentage }}%</span>
                            </h3>
                            <img class="productImage" v-if="chemical?.imageUrl" :src="chemical?.imageUrl" />
                            <bar-code class="barcode" v-if="chemical?.barcode" :barcode="chemical.barcode" />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselVolumeGallons.$model"
                                label="Vessel Volume"
                                required
                                :error-messages="vuelidateErrors(v$.vesselVolumeGallons)"
                                @input="v$.vesselVolumeGallons.$touch"
                                @blur="v$.vesselVolumeGallons.$touch"
                            />
                            <v-select
                                label="Chemical Use"
                                :items="chemical?.chemicalUses"
                                v-model="v$.chemicalUse.$model"
                                required
                                :error-messages="vuelidateErrors(v$.chemicalUse)"
                                @input="v$.chemicalUse.$touch"
                                @blur="v$.chemicalUse.$touch"
                            ></v-select>
                            <v-text-field
                                v-model="v$.desiredChangeAmount.$model"
                                label="Change Amount"
                                required
                                :error-messages="vuelidateErrors(v$.desiredChangeAmount)"
                                @input="v$.desiredChangeAmount.$touch"
                                @blur="v$.desiredChangeAmount.$touch"
                            />

                            <div class="text-center">
                                <v-btn color="blue-darken-1" @click="submit" :disabled="v$.$invalid">Compute Dose</v-btn>
                            </div>
                            <div v-if="doseResult?.error !== undefined">
                                <v-alert class="mt-6" type="warning" title="Dosing Error" :text="doseResult?.error" />
                            </div>
                            <div v-else-if="doseResult?.doses !== undefined">
                                <v-alert
                                    class="mt-6"
                                    v-for="dose in doseResult.doses"
                                    :key="dose.doseType"
                                    type="success"
                                    :title="`Add ${dose.amount} ${dose.unit}`"
                                    :text="formatDoseText(dose)"
                                />
                                <v-alert
                                    class="mt-6"
                                    v-if="doseResult.doses.length === 0"
                                    type="warning"
                                    title="No doses returned"
                                    text="Most likely, dosing is not available for this chemical type."
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <v-overlay :model-value="loading" class="align-center justify-center">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue-darken-1" @click="syncVModel(false)">Done</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { computed, reactive, watch } from 'vue'
import { ref } from 'vue'
import { required, minValue, maxValue } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { handleApiError, vuelidateErrors } from '@/lib/utils'
import type { PropType } from 'vue'
import type { ChemicalV2, ChemicalUsesV1, PutDoseChemicalResultV1, DoseV1 } from '@geckoal/gecko-api-client'
import BarCode from '../BarCode.vue'
import { formatIngredientName } from '@/lib/chemicals'

const props = defineProps({
    modelValue: {
        type: Boolean
    },
    accountId: {
        type: Number,
        required: false
    },
    chemical: {
        type: Object as PropType<ChemicalV2>,
        required: false
    },
    vesselVolume: {
        type: Number,
        required: false
    }
})

watch(
    () => props.vesselVolume,
    () => {
        if (props.vesselVolume) state.vesselVolumeGallons = props.vesselVolume
    }
)

const defaultChemicalUse = computed<ChemicalUsesV1 | undefined>(() => {
    return (props.chemical?.chemicalUses?.length ?? 0) > 0 ? (props.chemical?.chemicalUses[0] as ChemicalUsesV1) : undefined
})

watch(defaultChemicalUse, () => {
    state.chemicalUse = defaultChemicalUse.value
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
}>()

const loadingChemicalDetails = ref(false)
const doseResult = ref<PutDoseChemicalResultV1 | undefined>()

const loading = computed(() => {
    return loadingChemicalDetails.value
})

type State = {
    vesselVolumeGallons: number
    desiredChangeAmount: number
    chemicalUse: ChemicalUsesV1 | undefined
}

const state = reactive<State>({
    vesselVolumeGallons: props.vesselVolume ?? 10000,
    desiredChangeAmount: 0,
    chemicalUse: defaultChemicalUse.value
})

watch(
    () => props.modelValue,
    (value) => {
        if (value === true) {
            state.desiredChangeAmount = 0
            doseResult.value = undefined
        }
    }
)

const rules = {
    vesselVolumeGallons: { required, minValue: minValue(160), maxValue: maxValue(50000) },
    desiredChangeAmount: { required },
    chemicalUse: { required }
}

const v$ = useVuelidate(rules, state)

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

const submit = async () => {
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        console.warn('Form validation failed:', v$.value.$errors)
        return
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    handleApiError(
        async () => {
            loadingChemicalDetails.value = true

            if (props.chemical === undefined) return
            if (state.chemicalUse === undefined) return
            if (state.vesselVolumeGallons === undefined) return

            const result = await useApi().putDoseChemicalV1({
                putDoseChemicalRequestV1: {
                    accountId: props.accountId,
                    chemical: props.chemical,
                    chemicalUse: state.chemicalUse,
                    desiredChangeAmount: state.desiredChangeAmount,
                    volumeGallons: state.vesselVolumeGallons
                }
            })
            doseResult.value = result
        },
        async () => {
            loadingChemicalDetails.value = false
        }
    )
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

const formatDoseText = (dose: DoseV1) => {
    if (!dose.expectedChange) return ''
    let text = `To change ${dose.expectedChange?.amount} ${dose.expectedChange?.unit} in ${state.vesselVolumeGallons} gal.`
    if (dose.stepCaption) {
        text += ` (${dose.stepCaption})`
    }

    return text
}
</script>

<style lang="scss" scoped>
.dose {
    text-align: center;

    H1 {
        margin-bottom: 16px;
    }
}

.productImage {
    max-height: 300px;
    width: auto;
}

.productInfo {
    text-align: center;
}
</style>
