<template>
    <v-app v-if="isLoading">
        <div class="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
    </v-app>
    <v-app v-else>
        <v-navigation-drawer app v-model="drawer" temporary v-if="user">
            <v-list nav>
                <img class="menuTitle" src="/gecko-logo-blue.svg" />
                <v-list-item prepend-icon="mdi-home" title="Home" color="primary" :to="{ name: 'home' }"></v-list-item>
                <v-list-item prepend-icon="mdi-beaker-outline" title="Account Chemicals" color="primary" :to="{ name: 'AccountChemicals' }"></v-list-item>
                <v-list-item prepend-icon="mdi-beaker-outline" title="System Chemicals" color="primary" :to="{ name: 'AllSystemChemicals' }"></v-list-item>
                <v-list-item prepend-icon="mdi-led-strip" title="Recent Test Strip Scans" color="primary" :to="{ name: 'RecentTestStrips' }"></v-list-item>
                <v-list-item prepend-icon="mdi-view-grid" title="Test Strip Catalog" color="primary" :to="{ name: 'TestStripCatalog' }"></v-list-item>
                <v-list-item prepend-icon="mdi-star" title="Recent Feedback" color="primary" :to="{ name: 'RecentFeedback' }"></v-list-item>
                <v-list-item prepend-icon="mdi-cube-scan" title="Returned Monitors" color="primary" :to="{ name: 'ReturnedMonitorsScan' }"></v-list-item>
                <v-list-item
                    prepend-icon="mdi-cube-scan"
                    title="Prepare Replacement Monitor"
                    color="primary"
                    :to="{ name: 'ReplacementMonitors' }"
                ></v-list-item>
                <v-list-item-subtitle class="mt-5 mb-2">Engineering</v-list-item-subtitle>
                <v-list-item
                    prepend-icon="mdi-shovel"
                    title="Vessel Status Shoveler"
                    color="primary"
                    :to="{ name: 'VesselStatusUpdateShoveler' }"
                ></v-list-item>
                <v-list-item prepend-icon="mdi-cloud-print" title="CloudWatch Logs" color="primary" :to="{ name: 'CloudWatch' }"></v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app elevate-on-scroll v-if="user">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <div class="breadcrumbs">
                <!-- Vuetify beta breadcrumbs are fucked - can't use -->
                <span v-for="(item, index) of breadCrumbsStore.items" :key="index" class="breadcrumb">
                    <v-btn v-if="item.to" :to="item.to">{{ item.text }}</v-btn>
                    <div v-else class="breadcrumb-text">{{ item.text }}</div>
                    <div v-if="index < breadCrumbsStore.items.length - 1" class="slash">/</div>
                </span>
            </div>
            <v-spacer />

            <v-btn @click="dialog = true" class="ma-2" color="indigo" icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-menu v-if="user" min-width="200px" rounded location="start top">
                <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props">
                        <v-avatar>
                            <img :src="user.picture" referrerpolicy="no-referrer" />
                        </v-avatar>
                    </v-btn>
                </template>
                <v-card>
                    <div class="mx-auto text-center pa-4">
                        <v-avatar>
                            <img :src="user.picture" referrerpolicy="no-referrer" />
                        </v-avatar>
                        <h3>{{ user.name }}</h3>
                        <p class="text-caption mt-1">
                            {{ user.email }}
                        </p>
                        <v-divider class="my-3"></v-divider>
                        <v-btn depressed rounded variant="text" @click="logout">Logout</v-btn>
                    </div>
                </v-card>
            </v-menu>
        </v-app-bar>

        <v-main>
            <v-container fluid :style="{ 'background-color': backgroundColor }">
                <router-view></router-view>
            </v-container>
        </v-main>

        <v-footer class="bg-grey-darken-3">
            <v-container>
                <v-row class="text-center" justify="center" no-gutters>
                    <v-col>
                        <v-btn color="white" variant="text" class="mx-2" rounded="xl" :to="{ name: 'home' }"> HOME </v-btn>
                    </v-col>
                </v-row>
                <v-row class="text-center">
                    <v-col class="text-white mt-4 copy">©{{ new Date().getFullYear() }} Gecko Alliance</v-col>
                </v-row>
            </v-container>
        </v-footer>

        <v-dialog v-model="showGlobalError">
            <v-card>
                <v-card-text>
                    <v-alert type="error" icon="mdi-alert" title="Oops!">{{ globalErrorMessage }}</v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="danger" block @click="closeErrorDialog">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" retain-focus class="searchDialog" min-width="300" max-width="600">
            <v-card>
                <v-card-text>
                    <v-text-field
                        autofocus
                        v-model="searchTerm"
                        prepend-inner-icon="mdi-magnify"
                        :loading="loadingSearchResults"
                        label="Search"
                        placeholder="Search"
                        clearable
                        :hint="searchHint"
                    ></v-text-field>

                    <div>
                        <v-list lines="two" style="font-size: 1.2rem">
                            <div v-if="accountSearchResults && accountSearchResults.length" class="mb-4">
                                <v-divider></v-divider>
                                <v-list-subheader>Accounts</v-list-subheader>
                                <v-list-item
                                    v-for="result of accountSearchResults"
                                    :key="result.accountId"
                                    :value="result"
                                    @click="navigateToAccount(result)"
                                    color="primary"
                                    rounded="xl"
                                >
                                    <template v-if="user?.picture" v-slot:prepend>
                                        <v-avatar size="36px">
                                            <img :src="user.picture" referrerpolicy="no-referrer" />
                                        </v-avatar>
                                    </template>
                                    <v-list-item-title><span v-html="searchTermHighlightedHtml(userName(result))"></span></v-list-item-title>
                                    <v-list-item-subtitle
                                        ><span v-html="searchTermHighlightedHtml(`Account #${result.accountId}, ${result.email}`)"></span
                                    ></v-list-item-subtitle>
                                </v-list-item>
                            </div>
                            <div v-if="vesselSearchResults && vesselSearchResults.length">
                                <v-divider></v-divider>
                                <v-list-subheader>Vessels</v-list-subheader>
                                <v-list-item
                                    v-for="result of vesselSearchResults"
                                    :key="result.vesselId"
                                    :value="result"
                                    @click="navigateToVessel(result)"
                                    color="primary"
                                    rounded="xl"
                                >
                                    <v-list-item-title><span v-html="searchTermHighlightedHtml(result.name)"></span></v-list-item-title>
                                    <v-list-item-subtitle><span v-html="searchTermHighlightedHtml(addressLine(result))"></span></v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-1"
                                        ><span
                                            v-html="
                                                searchTermHighlightedHtml(`Account #${result.accountId}, Vessel #${result.vesselId},${addressLine(result)}`)
                                            "
                                        ></span
                                    ></v-list-item-subtitle>
                                </v-list-item>
                            </div>
                            <div v-if="monitorSearchResults && monitorSearchResults.length">
                                <v-divider></v-divider>
                                <v-list-subheader>Monitors</v-list-subheader>
                                <v-list-item
                                    v-for="result of monitorSearchResults"
                                    :key="result.monitorId"
                                    :value="result"
                                    @click="navigateToMonitor(result)"
                                    color="primary"
                                    rounded="xl"
                                >
                                    <v-list-item-title><span v-html="searchTermHighlightedHtml(result.monitorId)"></span></v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span
                                            v-if="result.vesselAccountId ?? result.owningAccountId"
                                            v-html="searchTermHighlightedHtml(`Account #${result.vesselAccountId ?? result.owningAccountId}`)"
                                        />
                                        <span v-if="result.sensorSerialNumber && (result.vesselAccountId ?? result.owningAccountId)">, </span>
                                        <span v-if="result.sensorSerialNumber" v-html="searchTermHighlightedHtml(`Sensor #${result.sensorSerialNumber}`)" />
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </div>
                            <div v-if="sensorSearchResults && sensorSearchResults.length">
                                <v-divider></v-divider>
                                <v-list-subheader>Sensors</v-list-subheader>
                                <v-list-item
                                    v-for="result of sensorSearchResults"
                                    :key="result.serialNumber"
                                    :value="result"
                                    @click="navigateToSensor(result)"
                                    color="primary"
                                    rounded="xl"
                                >
                                    <v-list-item-title><span v-html="searchTermHighlightedHtml(result.serialNumber)"></span></v-list-item-title>
                                </v-list-item>
                            </div>
                        </v-list>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useApi } from './api'
import type { AccountSearchResultV1, SensorSearchResultV1 } from '@geckoal/gecko-api-client'
import type { MonitorSearchResultV1 } from '@geckoal/gecko-api-client'
import type { VesselSearchResultV1 } from '@geckoal/gecko-api-client'
import { debounce } from '@geckoal/gecko-webapp-utils'
import { useBreadCrumbsStore } from './stores/breadCrumbs'
import { useGeneralStore } from './stores/general'
import { useAuth0 } from '@auth0/auth0-vue'
import { logout } from './lib/auth'
import './components/vesselCards/vesselCards.scss'

const breadCrumbsStore = useBreadCrumbsStore()
const generalStore = useGeneralStore()
const router = useRouter()
const auth = useAuth0()

const { isLoading } = auth
const user = auth.user

const drawer = ref(false)

const searchTerm = ref<string | undefined>(undefined)
const accountSearchResults = ref<AccountSearchResultV1[] | undefined>(undefined)
const vesselSearchResults = ref<VesselSearchResultV1[] | undefined>(undefined)
const monitorSearchResults = ref<MonitorSearchResultV1[] | undefined>(undefined)
const sensorSearchResults = ref<SensorSearchResultV1[] | undefined>(undefined)
const loadingSearchResults = ref(false)
const dialog = ref(false)

const escapeListener = (event: KeyboardEvent) => {
    if (event.key === 's' && event.ctrlKey) {
        dialog.value = true
    }
}

onMounted(() => {
    window.addEventListener('keydown', escapeListener)
})

onUnmounted(() => {
    window.removeEventListener('keydown', escapeListener)
})

const addressLine = (vessel: VesselSearchResultV1) => {
    if (!vessel) return undefined

    const { location } = vessel
    if (!location) return undefined

    let line = ''
    if (location.line1) line += location.line1
    if (location.locality || location.administrativeAreaLevel1) line += ', '
    if (location.locality) line += location.locality
    if (location.locality && location.administrativeAreaLevel1) line += ' '
    if (location.administrativeAreaLevel1) line += location.administrativeAreaLevel1
    return line
}

const canUseSearchTerm = computed(() => {
    if (!searchTerm.value) return false
    if (Number.isInteger(parseInt(searchTerm.value, 10))) return true // ID search
    return searchTerm.value.length > 2
})

watch(
    searchTerm,
    debounce(() => {
        if (!canUseSearchTerm.value) {
            accountSearchResults.value = undefined
            vesselSearchResults.value = undefined
            return
        }
        if (searchTerm.value) search(searchTerm.value)
    }, 300)
)

watch(dialog, () => {
    if (!dialog.value) searchTerm.value = undefined // Clear last search when redisplaying the search dialog
})

const showNoResults = computed(() => {
    if (!searchTerm.value || searchTerm.value.length <= 2) return false // don't expect results
    if (loadingSearchResults.value === true) return false // wait until loading finishes

    const accountResultCount = accountSearchResults.value?.length ?? 0
    const vesselResultCount = vesselSearchResults.value?.length ?? 0
    const monitorResultCount = monitorSearchResults.value?.length ?? 0
    const sensorResultCount = sensorSearchResults.value?.length ?? 0

    return accountResultCount === 0 && vesselResultCount === 0 && monitorResultCount === 0 && sensorResultCount === 0
})

const searchHint = computed(() => {
    if (!searchTerm.value || !searchTerm.value.length) return 'Start typing to search for accounts, vessels, monitors, and sensors'
    if (Number.isInteger(parseInt(searchTerm.value, 10))) return undefined // valid condition when searching for a vessel id
    if (searchTerm.value.length === 1) return 'Just a couple more characters'
    if (searchTerm.value.length === 2) return 'Just one more character'
    if (loadingSearchResults.value === true) return `Searching for '${searchTerm.value}'`
    if (showNoResults.value === true) return `No results for '${searchTerm.value}'`
    return undefined
})

const navigateToAccount = (account: AccountSearchResultV1) => {
    router.replace({ name: 'AccountDetails', params: { accountId: account.accountId } })
    dialog.value = false
}

const navigateToVessel = (vessel: VesselSearchResultV1) => {
    router.replace({ name: 'VesselDetails', params: { accountId: vessel.accountId, vesselId: vessel.vesselId } })
    dialog.value = false
}

const navigateToMonitor = (monitor: MonitorSearchResultV1) => {
    router.replace({ name: 'MonitorDetails', params: { monitorId: monitor.monitorId } })
    dialog.value = false
}

const navigateToSensor = (sensor: SensorSearchResultV1) => {
    router.replace({ name: 'SensorDetails', params: { serialNumber: sensor.serialNumber } })
    dialog.value = false
}

const search = async (searchTerm: string) => {
    if (!canUseSearchTerm.value) return
    try {
        loadingSearchResults.value = true
        const result = await useApi().searchAccounts({
            searchAccountsV1: {
                searchTerm
            }
        })
        accountSearchResults.value = result.accounts
        vesselSearchResults.value = result.vessels
        monitorSearchResults.value = result.monitors
        sensorSearchResults.value = result.sensors
    } finally {
        loadingSearchResults.value = false
    }
}

const backgroundColor = computed(() => {
    return generalStore.$state.backgroundColor
})

const globalErrorMessage = computed(() => {
    return generalStore.globalErrorMessage
})

const showGlobalError = computed(() => {
    return !!globalErrorMessage.value
})

const closeErrorDialog = () => {
    generalStore.$patch({
        globalErrorMessage: undefined
    })
}

const searchTermHighlightedHtml = (text: string | undefined) => {
    if (searchTerm.value === undefined || text === undefined) return text
    const reg = new RegExp(searchTerm.value, 'gi')
    return text.replace(reg, (str) => `<span style="background-color: rgba(255, 214, 63, 0.75);">${str}</span>`)
}

const userName = (result: AccountSearchResultV1) => {
    let name = ''
    if (result.firstName !== undefined) name += result.firstName
    if (result.firstName !== undefined && result.lastName !== undefined) name += ' '
    if (result.lastName !== undefined) name += result.lastName
    return name
}
</script>

<style lang="scss" scoped>
H3 {
    font-weight: 300;
    text-align: center;
}

.copy {
    font-weight: 300;
}

.menuTitle {
    width: 100px;
    display: block;
    margin: 10px auto 30px auto;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    .breadcrumb {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .breadcrumb-text {
        color: #555;
        margin: 0 12px;
    }

    .slash {
        font-size: 20px;
        color: #aaa;
        font-weight: 100;
    }
}
.loading {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
