import { FreshDeskTicketStatusesV1, type AccountFeedbackV1, type AccountTicketV1 } from '@geckoal/gecko-api-client'
import { add, isAfter } from 'date-fns'
import { defineStore } from 'pinia'

export type AccountState = {
    recentFeedbackAccountId: number | undefined
    recentFeedbackLoadedDate: Date | undefined
    loadingRecentFeedback: boolean
    recentFeedback?: AccountFeedbackV1[]

    recentTicketsAccountId: number | undefined
    recentTicketsLoadedDate: Date | undefined
    loadingRecentTickets: boolean
    recentTickets?: AccountTicketV1[]
}

function isOkToCache(loadedDate: Date | undefined, maxCacheDuration: Duration): boolean {
    if (loadedDate === undefined) return false
    const expiresDate = add(loadedDate, maxCacheDuration)
    return isAfter(expiresDate, new Date())
}

export const useAccountStore = defineStore({
    id: 'account',
    state: () => {
        return {
            recentFeedbackAccountId: undefined,
            loadingRecentFeedback: false,
            recentFeedback: undefined,

            recentTicketsAccountId: undefined,
            loadingRecentTickets: false,
            recentTickets: undefined
        } as AccountState
    },
    actions: {
        async loadRecentFeedback(accountId: number, forceLoad = false) {
            if (forceLoad === false && this.recentFeedbackAccountId === accountId && isOkToCache(this.recentFeedbackLoadedDate, { minutes: 10 })) return

            try {
                this.loadingRecentFeedback = true
                const result = await this.useApi().getAccountFeedbackV1({ accountId })
                this.recentFeedback = result.feedback
                this.recentFeedbackAccountId = accountId
                this.recentFeedbackLoadedDate = new Date()
            } catch (error) {
                this.recentFeedback = undefined
                this.recentFeedbackAccountId = undefined
                this.recentFeedbackLoadedDate = undefined
                throw error
            } finally {
                this.loadingRecentFeedback = false
            }
        },
        async loadRecentTickets(accountId: number, _forceLoad = false) {
            // if (forceLoad === false && this.recentTicketsAccountId === accountId && isOkToCache(this.recentTicketsLoadedDate, { minutes: 10 })) return
            // try {
            //     this.loadingRecentTickets = true
            //     const result = await this.useApi().getAccountTicketsV1({ accountId })
            //     this.recentTickets = result.tickets
            //     this.recentTicketsAccountId = accountId
            //     this.recentTicketsLoadedDate = new Date()
            // } catch (error) {
            //     this.recentTickets = undefined
            //     this.recentTicketsAccountId = undefined
            //     this.recentTicketsLoadedDate = undefined
            //     throw error
            // } finally {
            //     this.loadingRecentTickets = false
            // }
        },
        async loadVesselAccountHealthInfo(accountId: number, forceLoad = false) {
            await Promise.all([this.loadRecentTickets(accountId, forceLoad), this.loadRecentFeedback(accountId, forceLoad)])
        }
    },
    getters: {
        loadingVesselAccountHealthInfo: (state: AccountState) => state.loadingRecentFeedback || state.loadingRecentTickets,
        vesselAccountHealthInfo: (state: AccountState): { openTicketCount: number; mostRecentFeedback?: AccountFeedbackV1 } => {
            const mostRecentFeedback = state.recentFeedback && state.recentFeedback.length > 0 ? state.recentFeedback[0] : undefined

            const openTicketCount = state.recentTickets
                ? state.recentTickets.reduce((collector, ticket) => {
                      if (ticket.status === FreshDeskTicketStatusesV1.Open || ticket.status === FreshDeskTicketStatusesV1.Pending) {
                          collector = collector + 1
                      }
                      return collector
                  }, 0)
                : 0

            return {
                mostRecentFeedback,
                openTicketCount
            }
        }
    }
})
