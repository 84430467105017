<template>
    <div class="infoCard">
        <h3 v-if="title" class="mb-2">{{ title }}</h3>

        <div v-if="loading" class="text-center" :style="contentStyles">
            <v-progress-circular indeterminate color="blue" size="50"></v-progress-circular>
        </div>

        <div v-else-if="isEmpty" class="contents" :style="contentStyles">
            <slot name="empty">
                <v-sheet v-if="showSheet" rounded :style="contentStyles" class="empty">
                    <h3>{{ emptyTitle }}</h3>
                </v-sheet>
                <div v-else :style="contentStyles" class="empty">
                    <h3>{{ emptyTitle }}</h3>
                </div>
            </slot>
        </div>

        <div v-else class="contents">
            <v-sheet v-if="showSheet" rounded :style="contentStyles">
                <slot></slot>
            </v-sheet>
            <div v-else :style="contentStyles">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    },
    title: {
        type: String
    },
    minHeight: {
        type: Number,
        default: 60
    },
    isEmpty: {
        type: Boolean,
        default: false
    },
    emptyTitle: {
        type: String,
        default: 'Nothing to Show'
    },
    showSheet: {
        type: Boolean,
        default: true
    }
})

const contentStyles = computed(() => {
    return {
        'min-height': `${props.minHeight}px`
    }
})
</script>

<style lang="scss" scoped>
.infoCard {
    h3 {
        font-size: 1em;
        font-weight: 500;
    }

    .empty {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            font-weight: 200;
        }
    }
}

.contents {
    position: relative;
}
</style>
